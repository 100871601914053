import { Stack } from '@mui/material';
import { SimpleForm } from 'react-admin';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { ValidationError } from 'src/domains/club/contract/context';
import { validateEmail } from 'src/domains/club/members/detail/validators/validateEmail';
import {
  validateFirstName,
  validateLastName,
} from 'src/domains/club/members/detail/validators/validateName';
import { User } from 'src/types/contexts/auth';

import AvatarWrapper from './AvatarWrapper';
import { GeneralInfosUserDetails } from './GeneralInfosUserDetails';

const GeneralInfosUser = () => {
  const validateForm = () => async (values: Partial<User>) => {
    const errors: ValidationError = {};
    validateFirstName(values, errors);
    validateLastName(values, errors);
    validateEmail(values, errors);
    return Object.keys(errors).length > 0 ? errors : await dataProvider.postPersonalInfos(values);
  };

  const readonly = true;

  return (
    <SimpleForm
      toolbar={false}
      sx={{
        padding: readonly ? '0px 24px 30px 24px' : '0px 24px !important',
      }}
      validate={validateForm()}
    >
      <Stack
        direction={'column'}
        spacing={4}
        width={'100%'}
        sx={{
          marginBottom: '10px',
        }}
      >
        <AvatarWrapper readonly={readonly} />
        <GeneralInfosUserDetails readonly={readonly} />
      </Stack>
    </SimpleForm>
  );
};

export default GeneralInfosUser;
