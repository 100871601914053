import { Box, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { SaveButton } from 'react-admin';
import { CustomInputLabel } from 'src/domains/club/members/detail/fields/CustomInputLabel';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { tokens } from 'src/locales/tokens';

interface GeneralInfosUserDetailsProps {
  readonly: boolean;
}

export const GeneralInfosUserDetails = ({ readonly }: GeneralInfosUserDetailsProps) => {
  const { user } = useAuth();

  return (
    <div>
      <Stack
        direction={'row'}
        spacing={3}
      >
        <Box sx={{ width: '100%' }}>
          <CustomInputLabel
            source={'firstname'}
            label={t(tokens.domains.members.detail.label.firstName)}
            disabled={readonly}
            defaultValue={user?.firstname}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <CustomInputLabel
            source={'lastname'}
            label={t(tokens.domains.members.detail.label.lastName)}
            disabled={readonly}
            defaultValue={user?.lastname}
          />
        </Box>
      </Stack>
      <Stack
        direction={'row'}
        spacing={3}
      >
        <Stack sx={{ width: '100%' }}>
          <CustomInputLabel
            source={'email'}
            label={t(tokens.domains.members.detail.label.mail)}
            disabled={readonly}
            defaultValue={user?.email}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            {t(tokens.domains.settings.personalInfos.detail.emailSupport)}
          </Typography>
        </Stack>
        <Box sx={{ width: '100%' }}></Box>
      </Stack>
      {!readonly && (
        <Box
          display={'flex'}
          justifyContent={'end'}
          width={'100%'}
          margin="24px 0px"
        >
          <SaveButton
            label={t(tokens.common.button.validate)}
            type="submit"
            icon={<></>}
          />
        </Box>
      )}
    </div>
  );
};
