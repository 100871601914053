import { t } from 'i18next';
import { useGetList } from 'react-admin';
import { StatsBundle } from 'src/common/components/StatsBundle';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';
import { tokens } from 'src/locales/tokens';

import { StatsBundleSkeleton } from '../../clubs/skeletons/StatsBundleSkeleton';

export const RegistrationsStats = () => {
  const url = ClubResourceEnum.REGISTRATIONS;

  const { data, isLoading, error } = useGetList(url, {
    pagination: { page: 1, perPage: 1000000 },
  });

  const stats = [
    {
      statIconSrc: '/assets/icons/icon-sign.svg',
      statName: t(tokens.domains.clubs.detail.membershipsStats.pendingSignature),
      statStatus: 'pending_sign',
    },
    {
      statIconSrc: '/assets/icons/icon-wait.svg',
      statName: t(tokens.domains.clubs.detail.membershipsStats.pendingValidation),
      statStatus: 'user_accepted',
    },
    {
      statIconSrc: '/assets/icons/icon-no.svg',
      statName: t(tokens.domains.clubs.detail.membershipsStats.prospectDeclined),
      statStatus: 'user_refused',
    },
    {
      statIconSrc: '/assets/icons/icon-rejected.svg',
      statName: t(tokens.domains.clubs.detail.membershipsStats.presidentDeclined),
      statStatus: 'president_refused',
    },
    {
      statIconSrc: '/assets/icons/icon-expired.svg',
      statName: t(tokens.domains.clubs.detail.membershipsStats.expired),
      statStatus: 'expired',
    },
  ];

  if (isLoading) {
    return <StatsBundleSkeleton />;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <StatsBundle
      data={data ?? []}
      stats={stats}
    />
  );
};
