import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { RadioButtonGroupInput } from 'react-admin';
import { contractContext } from 'src/domains/club/contract/context';
import { SectionLayout } from 'src/domains/club/contract/layouts/SectionLayout';
import { dayOption } from 'src/domains/club/contract/steps/stepTwo/utils/dayOption';
import { CustomDateInput } from 'src/domains/club/members/detail/fields/CustomDateInput';
import { CustomInputLabel } from 'src/domains/club/members/detail/fields/CustomInputLabel';
import { CustomSelectLabel } from 'src/domains/club/members/detail/fields/CustomSelectLabel';
import { tokens } from 'src/locales/tokens';

export const Meetings: React.FC = () => {
  const { firstStep, setFirstStep } = React.useContext(contractContext);
  const [format, setFormat] = React.useState('weekly');
  const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormat(event.target.value);
  };
  return (
    <SectionLayout title={t(tokens.domains.contract.stepOne.meetings.title)}>
      <Box
        sx={{
          padding: '0px 24px',
          marginBottom: '30px',
        }}
      >
        <RadioButtonGroupInput
          source="format"
          name="format"
          label={`${t(tokens.domains.contract.stepOne.meetings.format.title)} *`}
          choices={[
            { id: t(tokens.filters.format.monthly), name: t(tokens.filters.format.monthly) },
            {
              id: t(tokens.filters.format.fortnightly),
              name: t(tokens.filters.format.fortnightly),
            },
            { id: t(tokens.filters.format.weekly), name: t(tokens.filters.format.weekly) },
          ]}
          onChange={handleRadioButtonChange}
          sx={{
            '& .MuiFormLabel-root': {
              fontSize: '19px',
              fontWeight: '600',
              color: 'inherit',
            },
            '& .MuiFormControlLabel-label': {
              fontSize: '14px',
            },
            '&.MuiFormHelperText-root': {
              marginLeft: '0px !important',
            },
          }}
          helperText={
            format === t(tokens.filters.format.weekly)
              ? t(tokens.domains.contract.stepOne.meetings.format.description.weekly)
              : format === t(tokens.filters.format.fortnightly)
                ? t(tokens.domains.contract.stepOne.meetings.format.description.fortnightly)
                : t(tokens.domains.contract.stepOne.meetings.format.description.monthly)
          }
        />
      </Box>
      <Stack flexDirection={'row'}>
        <Stack
          flexGrow="1"
          padding={'0px 24px 30px 24px'}
          spacing={4}
          flexBasis={'50%'}
        >
          <Stack
            direction="row"
            spacing={3}
            gap="55px"
          >
            <CustomSelectLabel
              source="dayWeek"
              name="dayWeek"
              label={`${t(tokens.domains.contract.stepOne.meetings.meetingDay.label)} *`}
              choices={dayOption}
              firstStep={firstStep}
              setFirstStep={setFirstStep}
            />
            <CustomDateInput
              source="startEvent"
              name="startEvent"
              label={`${t(tokens.domains.contract.stepOne.meetings.firstMeetingDate.label)} *`}
              firstStep={firstStep}
              setFirstStep={setFirstStep}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={3}
            gap="55px"
          >
            <CustomInputLabel
              source="openingTime"
              name="openingTime"
              label={`${t(tokens.domains.contract.stepOne.meetings.startingHour.label)} *`}
              isTime
            />
            <CustomInputLabel
              source="closingTime"
              name="closingTime"
              label={`${t(tokens.domains.contract.stepOne.meetings.endingHour.label)} *`}
              isTime
            />
          </Stack>
        </Stack>
      </Stack>
    </SectionLayout>
  );
};
