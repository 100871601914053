import { useListContext } from 'react-admin';

export type FilterValues = { [key: string]: string[] | Date };

interface SetFilters {
  (filters: FilterValues, displayedFilters: unknown, debounce?: boolean): void;
}

export const useReactAdminFilters = (contextNameProps?: string) => {
  const contextName = contextNameProps ? contextNameProps : 'default';
  // Forcer un nouveau contexte en combinant le nom du contexte avec la ressource
  const baseContext = useListContext();
  const context = useListContext(`${contextName}_${baseContext?.resource || ''}`);

  const filterValues = context.filterValues as FilterValues;
  const setFilters = context.setFilters as SetFilters;

  return { filterValues, setFilters };
};
