import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

export const DropZone = () => {
  return (
    <Tooltip title="Uploader une photo">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'grey.300',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
          }}
        >
          <FileUploadOutlinedIcon sx={{ color: 'grey.600' }} />
        </Box>
        <Stack
          sx={{
            gap: 0.5,
            textAlign: 'left',
            marginLeft: 2,
          }}
        >
          <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
            {t(tokens.domains.settings.personalInfos.detail.clickOrDrag)}
          </Typography>
          <Typography sx={{ fontSize: '12px', color: 'grey.600' }}>
            {t(tokens.domains.settings.personalInfos.detail.maxSize)}
          </Typography>
        </Stack>
      </Stack>
    </Tooltip>
  );
};
