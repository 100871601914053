import * as Sentry from '@sentry/react';

import { getSentryConfig } from './sentry';

function initSentry() {
  const dsn = import.meta.env.VITE_ADMIN_APP_SENTRY_DSN as string;
  const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT as string;
  const isLocalTesting = import.meta.env.VITE_SENTRY_LOCAL_TESTING === 'true';

  if (environment === 'development') {
    console.groupCollapsed('Sentry Initialize');
  }

  if (!dsn) {
    console.warn('Sentry DSN is not defined. Sentry will not be initialized.');
    if (environment === 'development') {
      console.groupEnd();
    }
    return;
  }

  if (environment === 'production' || environment === 'staging') {
    Sentry.init(getSentryConfig(dsn, environment));
  } else {
    if (isLocalTesting && environment === 'development') {
      console.log('✅ Sentry enabled (dev mode with local testing)');
      Sentry.init(getSentryConfig(dsn, environment));
    } else {
      console.log('❌ Sentry disabled (dev mode without local testing)');
    }
  }

  if (environment === 'development') {
    console.groupEnd();
  }
}

export default initSentry;
