import { t } from 'i18next';
import { Organization } from 'src/admin-api-types';
import { ValidationError } from 'src/domains/club/contract/context';
import { tokens } from 'src/locales/tokens';
import { User } from 'src/types/contexts/auth';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';

export const validateName = (values: Partial<Organization>, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.name)) {
    errors.name = t(tokens.utils.form.required);
  }
};

export const validateFirstName = (values: Partial<User>, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.firstname)) {
    errors.firstname = t(tokens.utils.form.required);
  } else if (values?.firstname && values.firstname.length > 50) {
    errors.firstname = t(tokens.utils.form.maxLength, { length: 50 });
  } else if (values?.firstname && values.firstname.length < 2) {
    errors.firstname = t(tokens.utils.form.minLength, { length: 2 });
  }
};

export const validateLastName = (values: Partial<User>, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.lastname)) {
    errors.lastname = t(tokens.utils.form.required);
  } else if (values?.lastname && values.lastname.length > 50) {
    errors.lastname = t(tokens.utils.form.maxLength, { length: 50 });
  } else if (values?.lastname && values.lastname.length < 2) {
    errors.lastname = t(tokens.utils.form.minLength, { length: 2 });
  }
};
