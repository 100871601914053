import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { PasswordInput, SaveButton, SimpleForm } from 'react-admin';
import { useForm } from 'react-hook-form';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { ValidationError } from 'src/domains/club/contract/context';
import {
  validateCurrentPassword,
  validateNewPassword,
  validateConfirmPassword,
} from 'src/domains/club/members/detail/validators/validatePassword';
import { tokens } from 'src/locales/tokens';

interface PasswordFormType {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const PasswordUser = () => {
  const readonly = false;
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [changePasswordInput, setChangePasswordInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const form = useForm<PasswordFormType>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    resetOptions: {
      keepDirtyValues: false, // désactive la conservation des valeurs modifiées
      keepValues: false, // désactive la conservation des valeurs
    },
  });

  const handleReset = () => {
    form.reset();
  };

  const onSubmit = async (values: PasswordFormType) => {
    const errors: ValidationError = {};

    //reset du message d'erreur ou de succès
    setSuccess(false);
    setFailed(false);
    setErrorMessage('');

    //validation des champs
    validateCurrentPassword(values, errors);
    validateNewPassword(values, errors);
    validateConfirmPassword(values, errors);

    if (Object.keys(errors).length > 0) {
      return errors;
    }

    try {
      await dataProvider.patchPassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      setSuccess(true);
      handleReset();
      setChangePasswordInput(false);
      return {};
    } catch (error) {
      setFailed(true);
      if (error.status === 422) {
        setErrorMessage(t(tokens.domains.settings.personalInfos.detail.invalidPassword));
      } else {
        setErrorMessage(error.message as string);
      }
      console.error('Erreur lors de la mise à jour du mot de passe:', error);
      return { _error: 'Une erreur est survenue lors de la mise à jour du mot de passe.' };
    }
  };

  return (
    <SimpleForm
      toolbar={false}
      sx={{
        padding: readonly ? '0px 24px 30px 24px' : '0px 24px !important',
      }}
      onSubmit={(values) => onSubmit(values)}
      onChange={() => setChangePasswordInput(true)}
    >
      <Stack
        direction={'column'}
        spacing={3}
        width={'50%'}
        sx={{
          marginBottom: '10px',
        }}
      >
        <PasswordInput
          inputProps={form.register('currentPassword')}
          source={'currentPassword'}
          label={t(tokens.domains.settings.personalInfos.detail.currentPassword)}
          disabled={readonly}
        />
        <PasswordInput
          inputProps={form.register('newPassword')}
          source={'newPassword'}
          label={t(tokens.domains.settings.personalInfos.detail.newPassword)}
          disabled={readonly}
        />
        <PasswordInput
          inputProps={form.register('confirmPassword')}
          source={'confirmPassword'}
          label={t(tokens.domains.settings.personalInfos.detail.confirmPassword)}
          disabled={readonly}
        />
      </Stack>
      <Stack
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 2,
        }}
      >
        {success && (
          <Stack
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'green' }}
          >
            <CheckCircleIcon />
            <Typography variant="body2">
              {t(tokens.domains.settings.personalInfos.detail.passwordChangedSuccess)}
            </Typography>
          </Stack>
        )}
        {failed && (
          <Stack
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'red' }}
          >
            <ErrorIcon />
            <Typography variant="body2">
              {t(tokens.domains.settings.personalInfos.detail.passwordChangedFail)}
            </Typography>
            <Typography variant="body2">{errorMessage}</Typography>
          </Stack>
        )}
      </Stack>
      {!readonly && (
        <Box
          display={'flex'}
          justifyContent={'end'}
          width={'100%'}
          margin="24px 0px"
        >
          <SaveButton
            label={t(tokens.common.button.validate)}
            type="submit"
            icon={<></>}
            disabled={!changePasswordInput}
          />
        </Box>
      )}
    </SimpleForm>
  );
};

export default PasswordUser;
