import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { useMockedUser } from 'src/hooks/useMockedUser';
import { usePopover } from 'src/hooks/usePopover';
import { getInitials } from 'src/utils/getInitials';

import { AccountPopover } from './AccountPopover';

export const AccountButton = () => {
  const { user } = useAuth();
  const userMock = useMockedUser();
  const popover = usePopover();

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%',
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
          }}
          // src={userMock.avatar}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'primary.contrastText',
            }}
          >
            <Typography sx={{ fontSize: '12px' }}>
              {getInitials(user?.firstname) + getInitials(user?.lastname)}
            </Typography>
          </Box>
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
