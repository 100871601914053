import { Grid } from '@mui/material';
import { CardStat } from 'src/domains/club/clubs/components/CardStat';

interface StatsBundleProps {
  data: Registration[];
  stats: Stats[];
}

// TODO: Will be replaced by admin-api-types when it will be available
type Registration = {
  status?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  email?: string;
  phoneNumber?: string;
  organizationName?: string;
  clubName?: string;
  clubDepartment?: string;
  createdAt?: string;
  updatedAt?: string;
  id?: string;
};

type Stats = {
  statIconSrc: string;
  statName: string;
  statStatus: string;
};

export const StatsBundle = ({ data, stats }: StatsBundleProps) => {
  return (
    <Grid
      container
      spacing={1}
      width={'100%'}
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: { sm: 'column', md: 'row' },
      }}
    >
      {stats.map((stat) => (
        <Grid
          item
          md={2}
          key={stat.statName}
        >
          <CardStat
            statIconSrc={stat.statIconSrc}
            statName={stat.statName}
            statNumber={data?.filter((item) => item.status === stat.statStatus).length ?? 0}
          />
        </Grid>
      ))}
    </Grid>
  );
};
