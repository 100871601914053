import { Box } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { DatePickerFilter, SearchFilter } from 'src/common/components/filters';
import ResetFiltersButton from 'src/common/components/ResetFiltersButton';
import { TagSummary } from 'src/common/components/TagSummary';
import { TotalItems } from 'src/common/components/TotalItems';
import { FilterLayout } from 'src/common/layouts/react-admin/FilterLayout';
import { useListExportModalContext } from 'src/common/modals/hooks/useListExportModalContext';
import TaggedFilter from 'src/contexts/common/filters/tagged-filter-context/components/TaggedFilter';
import { ClubUserSearchFilterEnum } from 'src/data/enum/local/searchFilterEnum';
import { useDatePickerRangeHandler } from 'src/hooks/useDatePickerRangeHandler';
import { useReactAdminFilters } from 'src/hooks/useReactAdminFilters';
import { tokens } from 'src/locales/tokens';
import { FilterLabels, FilterValues } from 'src/types/enums';
import { getDateYearsAgo } from 'src/utils/getDateYearsAgo';

export const ClubUserFilters: React.FC = () => {
  const { filterValues, setFilters } = useReactAdminFilters();
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickerRangeHandler();
  const { setGetFilters } = useListExportModalContext();

  useEffect(() => {
    setGetFilters(() => () => filterValues);
  }, [filterValues, setGetFilters]);

  return (
    // PENDING: Add filters in back end
    // <FilterLayout>
    //   <>
    //     <SearchFilter
    //       filterName={ClubUserSearchFilterEnum.SEARCH_TERM}
    //       filterValues={filterValues}
    //       placeholder={t(tokens.placeholder.memberSearchByName)}
    //     />
    //   </>
    //   <>
    //     <TotalItems />
    //   </>
    //   <>
    //     <TaggedFilter
    //       component={DatePickerFilter}
    //       label={FilterLabels.CreatedAt}
    //       value={FilterValues.CREATED_AFTER}
    //       minDate={getDateYearsAgo(15)}
    //       maxDate={endDate}
    //       setStartDate={setStartDate}
    //       filterValues={filterValues}
    //       setFilters={setFilters}
    //     />
    //     <TaggedFilter
    //       component={DatePickerFilter}
    //       minDate={startDate}
    //       label={FilterLabels.EndAt}
    //       value={FilterValues.CREATED_BEFORE}
    //       setEndDate={setEndDate}
    //       filterValues={filterValues}
    //       setFilters={setFilters}
    //     />
    //     <ResetFiltersButton
    //       setFilters={setFilters}
    //       filterValues={filterValues}
    //       setStartDate={setStartDate}
    //       setEndDate={setEndDate}
    //     />
    //   </>
    //   <>
    //     <TagSummary
    //       setStartDate={setStartDate}
    //       setEndDate={setEndDate}
    //       filterValues={filterValues}
    //       setFilters={setFilters}
    //     />
    //   </>
    // </FilterLayout>
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '30px' }}>
      <TotalItems />
    </Box>
  );
};
