import { Box, Card } from '@mui/material';
import { t } from 'i18next';
import { CardTitle } from 'src/domains/club/members/detail/common/CardTitle';
import { tokens } from 'src/locales/tokens';

import { MySettingsLayout } from '../layout/MySettingsLayout';

import GeneralInfosUser from './GeneralInfosUser';
import PasswordUser from './PasswordUser';

export const PersonalInfos = () => {
  return (
    <MySettingsLayout>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexBasis: '50%',
        }}
      >
        <CardTitle text={t(tokens.domains.settings.personalInfos.detail.generalInfos)} />
        <GeneralInfosUser />
      </Card>
      <Box sx={{ marginBottom: '40px' }} />
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexBasis: '50%',
        }}
      >
        <CardTitle text={t(tokens.domains.settings.personalInfos.detail.passwordReset)} />
        <PasswordUser />
      </Card>
    </MySettingsLayout>
  );
};
