import { AddAPhotoOutlined } from '@mui/icons-material';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Button } from 'react-admin';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { tokens } from 'src/locales/tokens';
import { getInitials } from 'src/utils/getInitials';
import { resizeImage } from 'src/utils/resizeImage';

import { UpdateProfilePicture } from './UpdateProfilePicture';

interface AvatarWrapperProps {
  readonly: boolean;
}

const AvatarWrapper = ({ readonly }: AvatarWrapperProps) => {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [pictureLoaded, setPictureLoaded] = useState(false);
  const [picture, setPicture] = useState<File | null>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = async (picture: File) => {
    try {
      const resizedPicture = await resizeImage(picture, 150, 150);
      await dataProvider.postPicture({
        rawFile: picture,
        resizedPicture,
      });
      setOpen(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(t(tokens.dataProvider.picture.postError) + error.message);
        console.error('error', error);
      }
    }
  };

  const handleRemove = async () => {
    try {
      await dataProvider.deletePicture();
      setPicture(null);
      setPictureLoaded(false);
      resetError();
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(t(tokens.dataProvider.picture.deleteError) + error.message);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    resetError();
  };

  const resetError = () => {
    setError(null);
  };

  const handleChange = (file: File) => {
    setPicture(file);
    setPictureLoaded(true);
  };
  return (
    <Stack
      direction={'row'}
      spacing={3}
    >
      <Stack
        sx={{
          position: 'relative',
        }}
      >
        <Avatar
          sx={{
            height: 100,
            width: 100,
          }}
          // src={userMock?.avatar as string}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'primary.contrastText',
            }}
          >
            <Typography sx={{ fontSize: '32px' }}>
              {getInitials(user?.firstname + ' ' + user?.lastname)}
            </Typography>
          </Box>
        </Avatar>
        {!readonly && (
          <Box
            onClick={handleClickOpen}
            sx={{
              position: 'absolute',
              top: 0,
              width: 100,
              height: 100,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              color: 'primary.contrastText',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              opacity: 0,
              '&:hover': {
                cursor: 'pointer',
                opacity: 1,
              },
            }}
          >
            <AddAPhotoOutlined />
            <Typography sx={{ fontSize: '12px', pt: 0.5 }}>
              {t(tokens.common.button.replace)}
            </Typography>
          </Box>
        )}
      </Stack>
      {!readonly && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              label={t(tokens.common.button.remove)}
              disabled={readonly}
              onClick={handleRemove}
              variant="outlined"
              sx={{
                color: 'primary.grey500',
              }}
            />
          </Box>
          <UpdateProfilePicture
            picture={picture}
            pictureLoaded={pictureLoaded}
            open={open}
            error={error}
            handleSave={handleSave}
            resetError={resetError}
            handleClose={handleClose}
            handleChange={handleChange}
            handleRemove={handleRemove}
          />
        </>
      )}
    </Stack>
  );
};

export default AvatarWrapper;
