import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useInput, InputProps } from 'react-admin';

interface DateInputLabelProps extends InputProps {
  source: string;
  placeholder: string;
  fullWidth?: boolean;
  type?: 'date' | 'text';
}

export const DateInputLabel = (props: DateInputLabelProps) => {
  const { field } = useInput<string>(props);
  const today = dayjs().format('YYYY-MM-DD');

  return (
    <TextField
      {...field}
      type={props.type || 'date'}
      placeholder={props.placeholder}
      fullWidth
      inputProps={{
        min: today,
        sx: {
          padding: '0 16px',
          fontWeight: 'regular',
          minHeight: '56px !important',
        },
      }}
      sx={{
        width: '100%',
        minHeight: '56px !important',
        '& .MuiInputBase-root': {
          borderRadius: '4px',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: 'grey.300',
          },
          '&:hover fieldset': {
            borderColor: 'grey.300',
          },
        },
        '& input[type="date"]::-webkit-calendar-picker-indicator': {
          fontSize: '20px',
          '&:hover': {
            cursor: 'pointer',
          },
        },
        '.MuiInputBase-adornedStart': {
          paddingLeft: 0,
        },
      }}
    />
  );
};
