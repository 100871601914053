import {
  BusinessCenter,
  Description,
  Groups,
  Error,
  Person,
  GroupAdd,
  Logout,
  Settings,
} from '@mui/icons-material';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import { useAgencies } from 'src/hooks/use-agencies';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';
// import { Agency } from 'src/types/config';
// import { UserRole } from 'src/types/enums/user-roles-enum';

export interface Item {
  title: string;
  path: string;
  icon: ReactElement;
  disabled?: boolean;
  label?: string;
  external?: boolean;
  items?: Item[];
  component?: React.ReactNode;
}

export interface Section {
  subheader?: string;
  items?: Item[];
}

const commonSections = [
  {
    subheader: tokens.nav.others.subheader,
    items: [
      {
        title: tokens.nav.others.settings.list,
        path: paths.others.settings.list,
        icon: <Settings fontSize="small" />,
      },
    ],
  },
];

const commonAdminSections = [
  {
    subheader: tokens.nav.club.subheader,
    items: [
      {
        title: tokens.nav.club.clubs.list,
        path: paths.club.clubs.list,
        icon: <BusinessCenter fontSize="small" />,
      },
      {
        title: tokens.nav.club.members.list,
        path: paths.club.members.list,
        icon: <Groups fontSize="small" />,
      },
      {
        title: tokens.nav.club.registrations.list,
        path: paths.club.registrations.list,
        icon: <GroupAdd fontSize="small" />,
      },
      {
        title: tokens.nav.club.users.list,
        path: paths.club.users.list,
        icon: <Person fontSize="small" />,
      },
      {
        title: tokens.nav.club.helpdesk.list,
        path: paths.club.helpdesk.list,
        icon: <Error fontSize="small" />,
      },
    ],
  },
  {
    subheader: tokens.nav.admin.subheader,
    items: [
      // {
      //   title: tokens.nav.agencies.list,
      //   path: paths.admin.agencies.list,
      //   icon: <Atom01 fontSize="small" />,
      // },
      {
        title: tokens.nav.admin.users.list,
        path: paths.admin.users.list,
        icon: <Person fontSize="small" />,
      },
    ],
  },
  ...commonSections,
];

const sectionsByRole = {
  ROLE_AGENCY: [
    {
      subheader: tokens.nav.club.subheader,
      items: [
        {
          title: tokens.nav.club.clubs.list,
          path: paths.club.clubs.list,
          icon: <BusinessCenter fontSize="small" />,
        },
        {
          title: tokens.nav.club.members.list,
          path: paths.club.members.list,
          icon: <Groups fontSize="small" />,
        },
        {
          title: tokens.nav.club.contracts.create,
          path: paths.club.contract.create,
          icon: <Description fontSize="small" />,
        },
      ],
    },
    ...commonSections,
  ],
  ROLE_SUPER_ADMIN: [
    ...commonAdminSections,
    // {
    //   subheader: tokens.nav.system.subheader,
    //   items: [
    //     {
    //       title: 'Work In Progress',
    //       path: '/path-to-be-defined',
    //       icon: <Atom01 fontSize="small" />,
    //     },
    //   ],
    // },
  ],
  ROLE_ADMIN: [...commonAdminSections],
};

export const useSections = (): Section[] => {
  const { t } = useTranslation();
  const { user } = useAuth();
  // const agencies = useAgencies();

  // const user = mockUser;
  // const agencies = mockAgencies;

  return useMemo(() => {
    // const agencies: Agency[] = [
    //   {
    //     '@id': '/agencies/018d9ca0-14e1-7ec3-bd04-21c1b584b1d4',
    //     '@type': 'Agency',
    //     name: 'Henry 1',
    //     id: '018d9ca0-14e1-7ec3-bd04-21c1b584b1d4',
    //   },
    //   {
    //     '@id': '/agencies/018d9ca0-14e1-7ec3-bd04-21c1b584b1d4',
    //     '@type': 'Agency',
    //     name: 'Henry 2',
    //     id: '018d9ca0-14e1-7ec3-bd04-21c1b584b1d5',
    //     children: [
    //       {
    //         '@id': '/subcategories/018d9ca0-14e1-7ec3-bd04-21c1b584b1d4',
    //         '@type': 'Agency',
    //         name: 'SubAgency 1',
    //         id: '018d9ca0-14e1-7ec3-bd04-21c1b584b1d6',
    //       },
    //       {
    //         '@id': '/subcategories/018d9ca0-14e1-7ec3-bd04-21c1b584b1d4',
    //         '@type': 'Agency',
    //         name: 'SubAgency 2',
    //         id: '018d9ca0-14e1-7ec3-bd04-21c1b584b1d7',
    //       },
    //     ],
    //   },
    // ];
    if (!user || user.roles.length === 0) return [];

    // const dynamicSections: Section[] = [];
    // if (user.roles.includes(UserRole.AGENCY) && agencies) {
    //   dynamicSections.push({
    //     subheader:
    //       agencies.length > 1
    //         ? t(tokens.nav.agencies.subheaderMultiple)
    //         : t(tokens.nav.agencies.subheaderSingle),
    //     items: agencies.map((agency) => ({
    //       title: agency.name,
    //       path: `agences/${agency.id}`,
    //       icon: <Atom01 fontSize="small" />,
    //       items: agency.children?.map((subAgency) => ({
    //         title: subAgency.name,
    //         path: `agences/${agency.id}/subcategories/${subAgency.id}`,
    //         icon: <Atom01 fontSize="small" />,
    //       })),
    //     })),
    //   });
    // }

    const baseSections: Section[] = sectionsByRole[user.roles[0]] || [];
    // const sections: Section[] = [...dynamicSections, ...baseSections];
    const sections: Section[] = [...baseSections];

    return sections.map((section) => ({
      ...section,
      subheader: section.subheader ? t(section.subheader) : undefined,
      items: section.items
        ? section.items.map((item) => ({
            ...item,
            title: t(item.title),
            icon: item.icon,
          }))
        : undefined,
    }));
  }, [t, user]);
};
