export const tokens = {
  common: {
    languageChanged: 'common.languageChanged',
    button: {
      cancel: 'common.button.cancel',
      save: 'common.button.save',
      close: 'common.button.close',
      confirm: 'common.button.confirm',
      export: 'common.button.export',
      retry: 'common.button.retry',
      generate: 'common.button.generate',
      validate: 'common.button.validate',
      replace: 'common.button.replace',
      remove: 'common.button.remove',
    },
    type: {
      president: 'common.type.president',
      member: 'common.type.member',
      foundingMember: 'common.type.foundingMember',
    },
  },
  auth: {
    login: {
      cardTitle: 'auth.login.cardTitle',
      forgotPasswordLink: 'auth.login.forgotPasswordLink',
    },
    requestPassword: {
      cardTitle: 'auth.requestPassword.cardTitle',
      submitPassword: 'auth.requestPassword.submitPassword',
      returnToLoginPage: 'auth.requestPassword.returnToLoginPage',
      emailSentSuccess: 'auth.requestPassword.emailSentSuccess',
      emailSentError: 'auth.requestPassword.emailSentError',
    },
    resetPassword: {
      cardTitle: 'auth.resetPassword.cardTitle',
      returnToRequestPage: 'auth.resetPassword.returnToRequestPage',
      submitPassword: 'auth.resetPassword.submitPassword',
      passwordReinitialized: 'auth.reinitializedEmail',
      mustContain: 'auth.resetPassword.mustContain',
      capitalLetterRule: 'auth.resetPassword.capitalLetterRule',
      lowerCaseLetterRule: 'auth.resetPassword.lowerCaseLetterRule',
      numberRule: 'auth.resetPassword.numberRule',
      specialCharacterRule: 'auth.resetPassword.specialCharacterRule',
      minimumLengthRule: 'auth.resetPassword.minimumLengthRule',
    },
    TwoFactorVerification: {
      tokenSentSuccessBody: 'auth.TwoFactorVerification.tokenSentSuccessBody',
      tokenSentSuccessTitle: 'auth.TwoFactorVerification.tokenSentSuccessTitle',
      submitCode: 'auth.TwoFactorVerification.submitCode',
    },
    form: {
      email: 'auth.form.email',
      password: 'auth.form.password',
      confirmPassword: 'auth.form.confirmPassword',
      submit: 'auth.form.submit',
      resetEmailSentSuccess: 'auth.resetEmailSentSuccess',
    },
  },
  nav: {
    overview: 'nav.overview',
    admin: {
      subheader: 'nav.admin.subheader',
      users: {
        list: 'nav.admin.user.list',
      },
    },
    club: {
      subheader: 'nav.club.subheader',
      clubs: {
        list: 'nav.club.clubs.list',
      },
      members: {
        list: 'nav.club.members.list',
      },
      registrations: {
        list: 'nav.club.registrations.list',
      },
      users: {
        users: 'nav.club.clubs.users.list',
        list: 'nav.club.users.list',
      },
      contracts: {
        list: 'nav.club.contracts.list',
        create: 'nav.club.contracts.create',
      },
      helpdesk: {
        list: 'nav.club.helpdesk.list',
      },
    },
    agencies: {
      subheaderSingle: 'nav.agencies.subheaderSingle',
      subheaderMultiple: 'nav.agencies.subheaderMultiple',
      list: 'nav.agencies.list',
    },
    system: {
      subheader: 'nav.system.subheader',
    },
    others: {
      subheader: 'nav.others.subheader',
      settings: {
        list: 'nav.others.settings.list',
        personal: {
          subheader: 'nav.others.settings.personal.subheader',
          personalInfos: {
            list: 'nav.others.settings.personal.personalInfos.list',
            updatePictureFailed: 'nav.others.settings.personal.personalInfos.updatePictureFailed',
          },
        },
      },
      logout: {
        list: 'nav.others.logout.list',
      },
    },
  },
  modale: {
    export: {
      list: 'modale.export.list',
      fileType: 'modale.export.fileType',
      noEmptyFileName: 'modale.export.noEmptyFileName',
      historyTitle: 'modale.export.historyTitle',
      emptyState: 'modale.export.emptyState',
      retry: 'modale.export.retry',
      cancel: 'modale.export.cancel',
      generate: 'modale.export.generate',
      createdAt: 'modale.export.createdAt',
      status: 'modale.export.status',
      restrictedChar: 'modale.export.restrictedChar',
      exportsPageLink: 'modale.export.exportsPageLink',
    },
    disableMember: {
      title: 'modale.disableMember.title',
      description: 'modale.disableMember.description',
      cancel: 'modale.disableMember.cancel',
      confirm: 'modale.disableMember.confirm',
      fail: 'modale.disableMember.fail',
      noMatch: 'modale.disableMember.noMatch',
      name: 'modale.disableMember.name',
      disableError: 'modale.disableMember.disableError',
      disableMemberScheduledDescription: 'modale.disableMember.disableMemberScheduledDescription',
    },
  },
  asyncMessages: {
    UserFetchError: 'asyncMessages.UserFetchError',
    UserIdMissing: 'asyncMessages.UserIdMissing',
    FileCreationFailed: 'asyncMessages.FileCreationFailed',
    FileCreationInProgress: 'asyncMessages.FileCreationInProgress',
    CSVImportFailed: 'asyncMessages.CSVImportFailed',
    FileRetryFailed: 'asyncMessages.FileRetryFailed',
    exportError: 'asyncMessages.exportError',
  },
  filters: {
    status: {
      initial: 'filters.status.initial',
      initialMembership: 'filters.status.initialMembership',
      active: 'filters.status.active',
      inactive: 'filters.status.inactive',
      declined: 'filters.status.declined',
      voided: 'filters.status.voided',
      signed: 'filters.status.signed',
      userAccepted: 'filters.status.userAccepted',
      userRefused: 'filters.status.userRefused',
      presidentAccepted: 'filters.status.presidentAccepted',
      presidentRefused: 'filters.status.presidentRefused',
      pendingSign: 'filters.status.pendingSign',
      waitingSign: 'filters.status.waitingSign',
      aborted: 'filters.status.aborted',
      submitted: 'filters.status.submitted',
      expired: 'filters.status.expired',
      guestAccepted: 'filters.status.guestAccepted',
      guestRefused: 'filters.status.guestRefused',
    },
    format: {
      monthly: 'filters.format.monthly',
      fortnightly: 'filters.format.fortnightly',
      weekly: 'filters.format.weekly',
    },
    type: {
      president: 'filters.format.president',
      member: 'filters.format.member',
      foundingMember: 'filters.format.foundingMember',
    },
    active: {
      isActive: 'filters.member.isActive',
      isNotActive: 'filters.member.isNotActive',
    },
    resetFilters: 'filters.filterInit',
  },
  domains: {
    helpdesk: {
      helpdeskHeaderTitle: 'domains.helpdesk.helpdeskHeaderTitle',
      list: {
        filters: {
          status: {
            created: 'domains.helpdesk.list.filters.status.created',
            in_progress: 'domains.helpdesk.list.filters.status.in_progress',
            done: 'domains.helpdesk.list.filters.status.done',
            aborted: 'domains.helpdesk.list.filters.status.aborted',
          },
          type: {
            public: 'domains.helpdesk.list.filters.type.public',
            user: 'domains.helpdesk.list.filters.type.user',
          },
        },
        grid: {
          noResult: 'domains.helpdesk.list.grid.noResult',
          company: 'domains.helpdesk.grid.company',
          type: 'domains.helpdesk.grid.type',
          topic: 'domains.helpdesk.grid.topic',
          manager: 'domains.helpdesk.grid.manager',
          department: 'domains.helpdesk.grid.department',
          createdAt: 'domains.helpdesk.grid.createdAt',
        },
      },
      detail: {
        info: {
          title: 'domains.helpdesk.detail.info.title',
          nature: 'domains.helpdesk.detail.nature',
          status: 'domains.helpdesk.detail.status',
          date: 'domains.helpdesk.detail.date',
          manager: 'domains.helpdesk.detail.manager',
          club: 'domains.helpdesk.detail.club',
        },
        applicant: {
          title: 'domains.helpdesk.detail.applicant.title',
          fullname: 'domains.helpdesk.detail.applicant.fullname',
          job: 'domains.helpdesk.detail.applicant.job',
          organization: 'domains.helpdesk.detail.applicant.organization',
          phone: 'domains.helpdesk.detail.applicant.phone',
          email: 'domains.helpdesk.detail.applicant.email',
          department: 'domains.helpdesk.detail.applicant.department',
        },
        message: {
          title: 'domains.helpdesk.detail.message.title',
        },
        notify: {
          success: 'domains.helpdesk.detail.notify.success',
          fail: 'domains.helpdesk.detail.notify.fail',
          notUpdated: 'domains.helpdesk.detail.notify.notUpdated',
        },
      },
    },
    clubs: {
      clubHeaderTitle: 'domains.clubs.clubHeaderTitle',
      createClub: 'domains.clubs.createClub',
      results: 'domains.clubs.results',
      list: {
        grid: {
          noResult: 'domains.clubs.grid.noResult',
        },
      },
      detail: {
        presidentCardtitle: 'domains.clubs.detail.presidentCardtitle',
        clubCardtitle: 'domains.clubs.detail.clubCardtitle',
        practicalInfoCardtitle: 'domains.clubs.detail.practicalInfoCardtitle',
        notify: {
          success: 'domains.clubs.detail.notify.success',
          fail: 'domains.clubs.detail.notify.fail',
        },
        general: 'domains.clubs.detail.general',
        memberships: 'domains.clubs.detail.memberships',
        membershipsStats: {
          pendingSignature: 'domains.clubs.detail.membershipsStats.pendingSignature',
          pendingValidation: 'domains.clubs.detail.membershipsStats.pendingValidation',
          prospectDeclined: 'domains.clubs.detail.membershipsStats.prospectDeclined',
          presidentDeclined: 'domains.clubs.detail.membershipsStats.presidentDeclined',
          expired: 'domains.clubs.detail.membershipsStats.expired',
        },
        invitations: 'domains.clubs.detail.invitations',
        invitationsStats: {
          aborted: 'domains.clubs.detail.invitationsStats.aborted',
          submitted: 'domains.clubs.detail.invitationsStats.submitted',
          expired: 'domains.clubs.detail.invitationsStats.expired',
          pendingGuestAccepted: 'domains.clubs.detail.invitationsStats.pendingGuestAccepted',
          guestDeclined: 'domains.clubs.detail.invitationsStats.guestDeclined',
          presidentDeclined: 'domains.clubs.detail.invitationsStats.presidentDeclined',
        },
        anonymized: 'domains.clubs.detail.anonymized',
      },
      chips: {
        download: 'domains.clubs.chips.download',
        see: 'domains.clubs.chips.see',
        disable: 'domains.clubs.chips.disable',
      },
    },
    members: {
      memberHeaderTitle: 'domains.members.memberHeaderTitle',
      list: {
        grid: {
          noResult: 'domains.members.grid.noResult',
        },
      },
      detail: {
        memberCardTitle: 'domains.members.detail.memberCardTitle',
        disableMemberCardTitle: 'domains.members.detail.disableMemberCardTitle',
        disableMemberCardDescription: 'domains.members.detail.disableMemberCardDescription',
        disableMemberCardDescriptionModal:
          'domains.members.detail.disableMemberCardDescriptionModal',
        organizationCardTitle: 'domains.members.detail.organizationCardTitle',
        ClubCardTitle: 'domains.members.detail.ClubCardTitle',
        label: {
          name: 'domains.members.detail.label.name',
          firstName: 'domains.members.detail.label.firstName',
          lastName: 'domains.members.detail.label.lastName',
          mail: 'domains.members.detail.label.mail',
          phone: 'domains.members.detail.label.phone',
          job: 'domains.members.detail.label.job',
          corporateName: 'domains.members.detail.label.corporateName',
          siret: 'domains.members.detail.label.siret',
          rna: 'domains.members.detail.label.rna',
          brand: 'domains.members.detail.label.brand',
          fullAddress: 'domains.members.detail.label.fullAddress',
          capital: 'domains.members.detail.label.capital',
          activity: 'domains.members.detail.label.activity',
          website: 'domains.members.detail.label.website',
          size: 'domains.members.detail.label.size',
          legalForm: 'domains.members.detail.label.legalForm',
          clubName: 'domains.members.detail.label.clubName',
          debitAmount: 'domains.members.detail.label.debitAmount',
          accountCreationDate: 'domains.members.detail.label.accountCreationDate',
          format: 'domains.members.detail.label.format',
          agency: 'domains.members.detail.label.agency',
          signatoryAgency: 'domains.members.detail.label.signatoryAgency',
          subAgency: 'domains.members.detail.label.subAgency',
          type: 'domains.members.detail.label.type',
          paymentFrequency: 'domains.members.detail.label.paymentFrequency',
          contractSignatureDate: 'domains.members.detail.label.contractSignatureDate',
          membershipContractSignatory: 'domains.members.detail.label.membershipContractSignatory',
          methodOfPayment: 'domains.members.detail.label.methodOfPayment',
          contract: 'domains.members.detail.label.contract',
          attachmentAgency: 'domains.members.detail.label.attachmentAgency',
          clubContract: 'domains.members.detail.label.clubContract',
          createdAt: 'domains.members.detail.label.createdAt',
          signedAt: 'domains.members.detail.label.signedAt',
          disabledAt: 'domains.members.detail.label.disabledAt',
          clubAddress: 'domains.members.detail.label.clubAddress',
          meetings: 'domains.members.detail.label.meetings',
          companyName: 'domains.members.detail.label.companyName',
        },
        alertMessage: {
          invalidSiret: 'domains.members.detail.alertMessage.invalidSiret',
          invalidRna: 'domains.members.detail.alertMessage.invalidRna',
        },
        notify: {
          success: 'domains.members.detail.notify.success',
          fail: 'domains.members.detail.notify.fail',
          notUpdated: 'domains.members.detail.notify.notUpdated',
        },
      },
      filters: {
        clubs: 'domains.members.filters.clubs',
        organization: 'domains.members.filters.organization',
        agencies: 'domains.members.filters.agencies',
        memberName: 'domains.members.filters.memberName',
      },
    },
    registrations: {
      registrationsHeaderTitle: 'domains.registrations.registrationHeaderTitle',
      list: {
        grid: {
          noResult: 'domains.registrations.grid.noResult',
        },
      },
      detail: {},
    },
    settings: {
      settingsHeaderTitle: 'domains.settings.settingsHeaderTitle',
      list: {
        grid: {
          noResult: 'domains.settings.grid.noResult',
        },
      },
      personalInfos: {
        detail: {
          invalidPassword: 'domains.settings.personalInfos.detail.invalidPassword',
          generalInfos: 'domains.settings.personalInfos.detail.generalInfos',
          passwordReset: 'domains.settings.personalInfos.detail.passwordReset',
          emailSupport: 'domains.settings.personalInfos.detail.emailSupport',
          updatePictureFailed: 'domains.settings.personalInfos.detail.updatePictureFailed',
          currentPassword: 'domains.settings.personalInfos.detail.currentPassword',
          newPassword: 'domains.settings.personalInfos.detail.newPassword',
          confirmPassword: 'domains.settings.personalInfos.detail.confirmPassword',
          passwordChangedSuccess: 'domains.settings.personalInfos.detail.passwordChangedSuccess',
          passwordChangedFail: 'domains.settings.personalInfos.detail.passwordChangedFail',
          uploadProfilePicture: 'domains.settings.personalInfos.detail.uploadProfilePicture',
          save: 'domains.settings.personalInfos.detail.save',
          cancel: 'domains.settings.personalInfos.detail.cancel',
          clickOrDrag: 'domains.settings.personalInfos.detail.clickOrDrag',
          maxSize: 'domains.settings.personalInfos.detail.maxSize',
          validate: 'domains.settings.personalInfos.detail.validate',
        },
      },
    },
    users: {
      usersHeaderTitle: 'domains.users.usersHeaderTitle',
      list: {
        grid: {
          noResult: 'domains.users.grid.noResult',
        },
      },
      detail: {},
    },
    contract: {
      contractHeaderTitle: 'domains.contract.contractHeaderTitle',
      info: 'domains.contract.info',
      stepper: {
        title: 'domains.contract.stepper.title',
        stepOne: 'domains.contract.stepper.stepOne',
        stepTwo: 'domains.contract.stepper.stepTwo',
        stepThree: 'domains.contract.stepper.stepThree',
        success: 'domains.contract.stepper.success',
      },
      stepOne: {
        title: 'domains.contract.stepOne.title',
        presidentInfos: {
          title: 'domains.contract.stepOne.presidentInfos.title',
          cardTitle: 'domains.contract.stepOne.presidentInfos.cardTitle',
          cardSubtitle: 'domains.contract.stepOne.presidentInfos.cardSubtitle',
          placeholder: 'domains.contract.stepOne.presidentInfos.placeholder',
          button: 'domains.contract.stepOne.presidentInfos.button',
        },
        generalInfos: {
          title: 'domains.contract.stepOne.generalInfos.title',
          clubName: {
            label: 'domains.contract.stepOne.generalInfos.clubName.label',
            placeholder: 'domains.contract.stepOne.generalInfos.clubName.placeholder',
          },
          presidentRemuneration: {
            label: 'domains.contract.stepOne.generalInfos.presidentRemuneration.label',
            options: {
              defaultOption: 'domains.contract.stepOne.generalInfos.clubName.options.defaultOption',
            },
          },
          siret: {
            label: 'domains.contract.stepOne.generalInfos.siret.label',
            placeholder: 'domains.contract.stepOne.generalInfos.siret.placeholder',
          },
          rna: {
            label: 'domains.contract.stepOne.generalInfos.rna.label',
            placeholder: 'domains.contract.stepOne.generalInfos.rna.placeholder',
          },
        },
        meetings: {
          title: 'domains.contract.stepOne.meetings.title',
          format: {
            title: 'domains.contract.stepOne.meetings.format.title',
            description: {
              monthly: 'domains.contract.stepOne.meetings.format.options.monthly',
              fortnightly: 'domains.contract.stepOne.meetings.format.options.fortnightly',
              weekly: 'domains.contract.stepOne.meetings.format.options.weekly',
            },
          },
          meetingDay: {
            label: 'domains.contract.stepOne.meetings.meetingDay.label',
            options: {
              defaultOption: 'domains.contract.stepOne.meetings.meetingDay.options.defaultOption',
            },
          },
          firstMeetingDate: {
            label: 'domains.contract.stepOne.meetings.firstMeetingDate.label',
            placeholder: 'domains.contract.stepOne.meetings.firstMeetingDate.placeholder',
          },
          startingHour: {
            label: 'domains.contract.stepOne.meetings.startingHour.label',
            placeholder: 'domains.contract.stepOne.meetings.startingHour.placeholder',
          },
          endingHour: {
            label: 'domains.contract.stepOne.meetings.endingHour.label',
            placeholder: 'domains.contract.stepOne.meetings.endingHour.placeholder',
          },
        },
        meetingPlace: {
          title: 'domains.contract.stepOne.meetingPlace.title',
          fullAddress: {
            label: 'domains.contract.stepOne.meetingPlace.fullAddress.label',
            placeholder: 'domains.contract.stepOne.meetingPlace.fullAddress.placeholder',
          },
          addressNotFound: 'domains.contract.stepOne.meetingPlace.addressNotFound',
          meetingName: {
            label: 'domains.contract.stepOne.meetingPlace.meetingName.label',
            placeholder: 'domains.contract.stepOne.meetingPlace.meetingName.placeholder',
          },
          address: {
            label: 'domains.contract.stepOne.meetingPlace.address.label',
            placeholder: 'domains.contract.stepOne.meetingPlace.address.placeholder',
          },
          postalCode: {
            label: 'domains.contract.stepOne.meetingPlace.postalCode.label',
            placeholder: 'domains.contract.stepOne.meetingPlace.postalCode.placeholder',
          },
          city: {
            label: 'domains.contract.stepOne.meetingPlace.city.label',
            placeholder: 'domains.contract.stepOne.meetingPlace.city.placeholder',
          },
          country: {
            label: 'domains.contract.stepOne.meetingPlace.country.label',
            placeholder: 'domains.contract.stepOne.meetingPlace.country.placeholder',
            options: {
              defaultOption: 'domains.contract.stepOne.meetingPlace.country.options.defaultOption',
            },
          },
          closestAddress: {
            label: 'domains.contract.stepOne.meetingPlace.closestAddress.label',
            placeholder: 'domains.contract.stepOne.meetingPlace.closestAddress.placeholder',
          },
        },
      },
      stepTwo: {
        title: 'domains.contract.stepTwo.title',
        companyInfos: {
          title: 'domains.contract.stepTwo.companyInfos.title',
          legalForm: {
            label: 'domains.contract.stepTwo.companyInfos.legalForm.label',
            options: {
              defaultOption:
                'domains.contract.stepTwo.companyInfos.legalForm.options.defaultOption',
              individual: 'domains.contract.stepTwo.companyInfos.legalForm.options.individual',
              EIRL: 'domains.contract.stepTwo.companyInfos.legalForm.options.EIRL',
              EURL: 'domains.contract.stepTwo.companyInfos.legalForm.options.EURL',
              SARL: 'domains.contract.stepTwo.companyInfos.legalForm.options.SARL',
              SASU: 'domains.contract.stepTwo.companyInfos.legalForm.options.SASU',
              SAS: 'domains.contract.stepTwo.companyInfos.legalForm.options.SAS',
              SA: 'domains.contract.stepTwo.companyInfos.legalForm.options.SA',
              SCS: 'domains.contract.stepTwo.companyInfos.legalForm.options.SCS',
              SCA: 'domains.contract.stepTwo.companyInfos.legalForm.options.SCA',
              SNC: 'domains.contract.stepTwo.companyInfos.legalForm.options.SNC',
              association: 'domains.contract.stepTwo.companyInfos.legalForm.options.association',
            },
          },
          name: {
            label: 'domains.contract.stepTwo.companyInfos.name.label',
            placeholder: 'domains.contract.stepTwo.companyInfos.name.placeholder',
          },
          brand: {
            label: 'domains.contract.stepTwo.companyInfos.brand.label',
            placeholder: 'domains.contract.stepTwo.companyInfos.brand.placeholder',
          },
          rcs: {
            label: 'domains.contract.stepTwo.companyInfos.rcs.label',
            placeholder: 'domains.contract.stepTwo.companyInfos.rcs.placeholder',
          },
          activity: {
            label: 'domains.contract.stepTwo.companyInfos.activity.label',
            placeholder: 'domains.contract.stepTwo.companyInfos.activity.placeholder',
          },
          compagnyAddress: {
            label: 'domains.contract.stepOne.meetingPlace.compagnyAddress.label',
            placeholder: 'domains.contract.stepOne.meetingPlace.compagnyAddress.placeholder',
          },
          compagnyPostalCode: {
            label: 'domains.contract.stepOne.meetingPlace.compagnyPostalCode.label',
            placeholder: 'domains.contract.stepOne.meetingPlace.compagnyPostalCode.placeholder',
          },
          compagnyCity: {
            label: 'domains.contract.stepOne.meetingPlace.compagnyCity.label',
            placeholder: 'domains.contract.stepOne.meetingPlace.compagnyCity.placeholder',
          },
          compagnyCountry: {
            label: 'domains.contract.stepOne.meetingPlace.compagnyCountry.label',
            placeholder: 'domains.contract.stepOne.meetingPlace.compagnyCountry.placeholder',
          },
          compagnySize: {
            label: 'domains.contract.stepTwo.companyInfos.compagnySize.label',
            placeholder: 'domains.contract.stepTwo.companyInfos.compagnySize.placeholder',
            tooltip: 'domains.contract.stepTwo.companyInfos.compagnySize.tooltip',
          },
          compagnyCapital: {
            label: 'domains.contract.stepTwo.companyInfos.compagnyCapital.label',
            placeholder: 'domains.contract.stepTwo.companyInfos.compagnyCapital.placeholder',
          },
        },
        signatory: {
          title: 'domains.contract.stepTwo.signatory.title',
          civility: {
            label: 'domains.contract.stepTwo.signatory.civility.label',
            options: {
              defaultOption: 'domains.contract.stepTwo.signatory.civility.options.defaultOption',
              miss: 'domains.contract.stepTwo.signatory.civility.options.miss',
              mister: 'domains.contract.stepTwo.signatory.civility.options.mister',
            },
          },
          recurring: {
            label: 'domains.contract.stepTwo.signatory.recurring.label',
            options: {
              defaultOption: 'domains.contract.stepTwo.signatory.recurring.options.defaultOption',
            },
          },
          signatoryFirstName: {
            label: 'domains.contract.stepTwo.signatory.firstName.label',
            placeholder: 'domains.contract.stepTwo.signatory.firstName.placeholder',
          },
          signatoryLastName: {
            label: 'domains.contract.stepTwo.signatory.lastName.label',
            placeholder: 'domains.contract.stepTwo.signatory.lastName.placeholder',
          },
          signatoryEmail: {
            label: 'domains.contract.stepTwo.signatory.email.label',
            placeholder: 'domains.contract.stepTwo.signatory.email.placeholder',
            emailVisibility: 'domains.contract.stepTwo.signatory.email.emailVisibility',
          },
          signatoryPhone: {
            label: 'domains.contract.stepTwo.signatory.phone.label',
            placeholder: 'domains.contract.stepTwo.signatory.phone.placeholder',
            phoneVisibility: 'domains.contract.stepTwo.signatory.phone.phoneVisibility',
          },
        },
      },
      modal: {
        title: 'domains.contract.modal.title',
        description: 'domains.contract.modal.description',
        cancel: 'domains.contract.modal.cancel',
        confirm: 'domains.contract.modal.confirm',
        fail: 'domains.contract.modal.fail',
      },
      stepThree: {
        title: 'domains.contract.stepThree.title',
        typoOne: 'domains.contract.stepThree.typoOne',
        typoTwo: 'domains.contract.stepThree.typoTwo',
        typoThree: 'domains.contract.stepThree.typoThree',
        button: 'domains.contract.stepThree.button',
      },
      validators: {
        siretOrRna: 'domains.contract.validators.siretOrRna',
        rnaOrSiret: 'domains.contract.validators.rnaOrSiret',
        invalidPostalcode: 'domains.contract.validators.invalidPostalcode',
        startEventNotMatchingDayWeek: 'domains.contract.validators.startEventNotMatchingDayWeek',
      },
    },
  },
  seo: {
    club: {
      title: 'seo.club.title',
    },
    member: {
      title: 'seo.member.title',
    },
    user: {
      title: 'seo.user.title',
    },
    registrations: {
      title: 'seo.registrations.title',
    },
    helpdesk: {
      title: 'seo.helpdesk.title',
    },
    settings: {
      title: 'seo.settings.title',
    },
  },
  breadcrumb: {
    memberList: 'breadcrumb.memberList',
    clubList: 'breadcrumb.clubList',
    helpdeskList: 'breadcrumb.helpdeskList',
  },
  headerColumnReactAdmin: {
    clubName: 'headerColumnReactAdmin.clubName',
    president: 'headerColumnReactAdmin.president',
    agency: 'headerColumnReactAdmin.agency',
    format: 'headerColumnReactAdmin.format',
    department: 'headerColumnReactAdmin.department',
    status: 'headerColumnReactAdmin.status',
    members: 'headerColumnReactAdmin.members',
    createdAt: 'headerColumnReactAdmin.createdAt',
    action: 'headerColumnReactAdmin.action',
    memberName: 'headerColumnReactAdmin.memberName',
    organization: 'headerColumnReactAdmin.organization',
    type: 'headerColumnReactAdmin.type',
    payment: 'headerColumnReactAdmin.payment',
    signedAt: 'headerColumnReactAdmin.signedAt',
    disabledAt: 'headerColumnReactAdmin.disabledAt',
    modifyAt: 'headerColumnReactAdmin.modifyAt',
    isActive: 'headerColumnReactAdmin.isActive',
    role: 'headerColumnReactAdmin.role',
    invitedBy: 'headerColumnReactAdmin.invitedBy',
    dateDiscoveryMeeting: 'headerColumnReactAdmin.dateDiscoveryMeeting',
    guestName: 'headerColumnReactAdmin.guestName',
  },
  placeholder: {
    globalSearch: 'placeholder.globalSearch',
    clubNameSearch: 'placeholder.clubNameSearch',
    agencySearch: 'placeholder.agencySearch',
    adminUserSearch: 'placeholder.adminUserSearch',
    memberSearch: 'placeholder.memberSearch',
    memberSearchByName: 'placeholder.memberSearchByName',
    clubSearch: 'placeholder.clubSearch',
    organizationSearch: 'placeholder.organizationSearch',
    clubInvitationsSearch: 'placeholder.clubInvitationsSearch',
  },
  utils: {
    isValidSiret: {
      siretRequired: 'utils.isValidSiret.siretRequired',
      siretInvalid: 'utils.isValidSiret.siretInvalid',
      siretFormatInvalid: 'utils.isValidSiret.siretFormatInvalid',
      updatedSirenNotAllowed: 'utils.isValidSiret.updatedSirenNotAllowed',
    },
    isValidRna: {
      rnaRequired: 'utils.isValidRna.siretRequired',
      rnaInvalid: 'utils.isValidRna.rnaInvalid',
    },
    days: {
      monday: 'utils.days.monday',
      tuesday: 'utils.days.tuesday',
      wednesday: 'utils.days.wednesday',
      thursday: 'utils.days.thursday',
      friday: 'utils.days.friday',
      saturday: 'utils.days.saturday',
      sunday: 'utils.days.sunday',
    },
    format: {
      fortnightly: 'utils.format.fortnightly',
      monthly: 'utils.format.monthly',
      weekly: 'utils.format.weekly',
    },
    step: {
      previous: 'utils.step.previous',
      next: 'utils.step.next',
    },
    form: {
      required: 'utils.form.required',
      maxLength: 'utils.form.maxLength',
      minLength: 'utils.form.minLength',
      endHourBeforeStartHour: 'utils.form.endHourBeforeStartHour',
      invalidEmail: 'utils.form.invalidEmail',
      invalidPassword: 'utils.form.invalidPassword',
      passwordNotMatch: 'utils.form.passwordNotMatch',
      mustBeAnInteger: 'utils.form.mustBeAnInteger',
      mustBeANumber: 'utils.form.mustBeANumber',
      maxLengthExceeded: 'utils.form.maxLengthExceeded',
      noOptionsText: 'utils.form.noOptionsText',
      selectElementFromList: 'utils.form.selectElementFromList',
      errorOccured: 'utils.form.errorOccured',
    },
    country: {
      france: 'utils.country.france',
      spain: 'utils.country.spain',
      switzerland: 'utils.country.switzerland',
    },
    error: {
      unknown: 'utils.error.unknown',
    },
    maintenance: {
      title: 'utils.maintenance.title',
      description: 'utils.maintenance.description',
    },
  },
  settingsDrawer: {
    title: 'settingsDrawer.title',
    color: {
      title: 'settingsDrawer.color.title',
      green: 'settingsDrawer.color.green',
      blue: 'settingsDrawer.color.blue',
      indigo: 'settingsDrawer.color.indigo',
      purple: 'settingsDrawer.color.purple',
    },
    mode: {
      title: 'settingsDrawer.mode.title',
      light: 'settingsDrawer.mode.light',
      dark: 'settingsDrawer.mode.dark',
    },
    nav: {
      title: 'settingsDrawer.nav.title',
      blendIn: 'settingsDrawer.nav.blendIn',
      discrete: 'settingsDrawer.nav.discrete',
      evident: 'settingsDrawer.nav.evident',
    },
    contrast: {
      title: 'settingsDrawer.contrast.title',
      normal: 'settingsDrawer.contrast.normal',
      high: 'settingsDrawer.contrast.high',
    },
  },
  dataProvider: {
    contract: {
      error: 'dataProvider.contract.error',
      error500: 'dataProvider.contract.error500',
    },
    user: {
      postError: 'dataProvider.user.postError',
    },
    picture: {
      postError: 'dataProvider.picture.postError',
      deleteError: 'dataProvider.picture.deleteError',
    },
    export: {
      downloadError: 'dataProvider.export.downloadError',
      cancelError: 'dataProvider.export.cancelError',
      retryError: 'dataProvider.export.retryError',
      postError: 'dataProvider.export.postError',
    },
  },
} as const;
