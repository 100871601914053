import { Stack } from '@mui/material';
import { LayoutComponent } from 'react-admin';
import { usePathname } from 'src/hooks/usePathname';
import { SideNavSection } from 'src/layouts/dashboard/vertical-layout';

import { useMySettingsSections } from './mySettingsConfig';
export const MySettingsList: LayoutComponent = () => {
  const sections = useMySettingsSections();
  const pathname = usePathname();

  return (
    <Stack component="nav">
      {sections.map((section, index) => (
        <SideNavSection
          items={section.items || []}
          key={index}
          pathname={pathname}
          subheader={section.subheader}
        />
      ))}
    </Stack>
  );
};
