import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { LogoNavV2 } from 'src/components/logos/LogoNavV2';
import { RouterLink } from 'src/components/RouterLink';
import { NavColor, useCssVars } from 'src/hooks/theme/useCssVarsDesktop';
import { usePathname } from 'src/hooks/usePathname';
import { paths } from 'src/paths';

import { Section } from '../config';

import { SideNavSection } from './SideNavSection';

const SIDE_NAV_WIDTH = 280;

interface SideNavProps {
  color?: NavColor;
  sections: Section[];
}

export const SideNav: React.FC<SideNavProps> = (props) => {
  const { color = 'evident', sections = [] } = props;
  const pathname = usePathname();
  const cssVars = useCssVars(color);
  return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          borderRightColor: 'var(--nav-border-color)',
          borderRightStyle: 'solid',
          borderRightWidth: 1,
          color: 'var(--nav-color)',
          width: SIDE_NAV_WIDTH,
        },
      }}
      variant="permanent"
    >
      <Stack sx={{ height: '100%' }}>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{ p: 3 }}
        >
          <Box
            component={(props: React.ComponentPropsWithoutRef<'a'>) => (
              <RouterLink
                href={paths.index}
                {...props}
              />
            )}
            sx={{
              borderColor: 'var(--nav-logo-border)',
              borderRadius: 1,
              borderStyle: 'solid',
              borderWidth: 1,
              display: 'flex',
              height: 40,
              p: '4px',
              width: 40,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* <LogoNav /> */}
            <LogoNavV2 />
          </Box>
          <Box>
            <Typography fontWeight="500">Dynadmin</Typography>
          </Box>
        </Stack>
        <Stack
          component="nav"
          spacing={2}
          sx={{
            flexGrow: 1,
            px: 2,
          }}
        >
          {sections.map((section, index) => (
            <SideNavSection
              items={section.items || []}
              key={index}
              pathname={pathname}
              subheader={section.subheader}
            />
          ))}
        </Stack>
      </Stack>
    </Drawer>
  );
};
