import { tokens } from '../tokens';

export const fr = {
  [tokens.auth.login.cardTitle]: 'Se connecter',
  [tokens.auth.login.forgotPasswordLink]: 'Mot de passe oublié',
  [tokens.auth.resetPassword.cardTitle]: 'Nouveau mot de passe',
  [tokens.auth.requestPassword.cardTitle]: 'Réinitialiser mon mot de passe',
  [tokens.auth.form.email]: 'Adresse e-mail',
  [tokens.auth.form.password]: 'Mot de passe',
  [tokens.auth.form.confirmPassword]: 'Confirmer le mot de passe',
  [tokens.auth.form.submit]: 'Je me connecte',
  [tokens.auth.requestPassword.submitPassword]: 'Recevoir un lien de réinitilisation',
  [tokens.auth.resetPassword.returnToRequestPage]: 'Renvoyer un lien de réinitialisation',
  [tokens.auth.resetPassword.submitPassword]: 'Modifier mon mot de passe',
  [tokens.auth.resetPassword.mustContain]: 'Le mot de passe doit au moins contenir :',
  [tokens.auth.resetPassword.capitalLetterRule]: 'Une lettre majuscule',
  [tokens.auth.resetPassword.lowerCaseLetterRule]: 'Une lettre minuscule',
  [tokens.auth.resetPassword.numberRule]: 'Un chiffre',
  [tokens.auth.resetPassword.specialCharacterRule]: 'Un caractère spécial',
  [tokens.auth.resetPassword.minimumLengthRule]: '8 caractères de long',
  [tokens.auth.requestPassword.emailSentSuccess]:
    "Un e-mail a été envoyé avec succès à l'adresse {{email}}, le lien expirera à {{expired_at}}",
  [tokens.auth.requestPassword.emailSentError]:
    "Une erreur est survenue lors de l'envoi de l'e-mail",
  [tokens.auth.resetPassword.passwordReinitialized]:
    "Le mot de passe associé à l'adresse {{email}} a bien été réinitialisé",
  [tokens.auth.form.resetEmailSentSuccess]: 'Votre mot de passe a été réinitialisé avec succès',
  [tokens.auth.requestPassword.returnToLoginPage]: 'Retour à la page de connexion',
  [tokens.common.languageChanged]: 'Langue modifiée',
  [tokens.nav.club.subheader]: "Club d'affaires",
  [tokens.nav.agencies.subheaderSingle]: 'Mon agence',
  [tokens.nav.agencies.subheaderMultiple]: 'Mes agences',
  [tokens.nav.system.subheader]: 'Système',
  [tokens.nav.club.clubs.list]: 'Clubs',
  [tokens.nav.club.members.list]: 'Membres',
  [tokens.nav.club.helpdesk.list]: 'Support',
  [tokens.nav.club.registrations.list]: 'Adhésions',
  [tokens.nav.club.users.list]: 'Comptes',
  [tokens.nav.agencies.list]: 'Agences',
  [tokens.nav.overview]: "Vue d'ensemble",
  [tokens.nav.admin.subheader]: 'Administration',
  [tokens.nav.admin.users.list]: 'Utilisateurs',
  [tokens.nav.others.subheader]: 'Autres',
  [tokens.nav.others.settings.list]: 'Mes paramètres',
  [tokens.nav.others.settings.personal.subheader]: 'Personnel',
  [tokens.nav.others.settings.personal.personalInfos.list]: 'Informations personnelles',
  [tokens.nav.others.logout.list]: 'Déconnexion',
  [tokens.auth.TwoFactorVerification.tokenSentSuccessBody]:
    'Un code à usage unique vous a été envoyé par email',
  [tokens.auth.TwoFactorVerification.tokenSentSuccessTitle]: 'Vérifiez vos e-mails',
  [tokens.auth.TwoFactorVerification.submitCode]: 'Vérifier mon code',
  [tokens.modale.export.list]: 'Exporter la liste',
  [tokens.modale.export.fileType]: 'Votre fichier sera exporté au format CSV.',
  [tokens.modale.export.noEmptyFileName]: 'Le nom du fichier doit être renseigné.',
  [tokens.modale.export.historyTitle]: 'Historique de vos exports',
  [tokens.modale.export.emptyState]: "Vous n'avez effectué aucun export pour le moment.",
  [tokens.modale.export.retry]: 'Réessayer',
  [tokens.modale.export.cancel]: 'Annuler',
  [tokens.modale.export.generate]: 'Valider',
  [tokens.modale.export.createdAt]: "Date d'export :",
  [tokens.modale.export.status]: 'Etat :',
  [tokens.modale.export.restrictedChar]:
    'Le nom du fichier ne doit pas contenir de caractères spéciaux.',
  [tokens.modale.export.exportsPageLink]: 'Voir la liste des exports',
  [tokens.asyncMessages.UserFetchError]:
    "Une erreur est survenue lors de la récupération des droits d'accès. Veuillez réessayer plus tard ou contacter le support si le problème persiste.",
  [tokens.asyncMessages.UserIdMissing]:
    "Impossible de récupérer les informations de l'utilisateur en raison d'une erreur système. Veuillez contacter le support.",
  [tokens.asyncMessages.FileCreationFailed]: 'La création du fichier CSV a échoué',
  [tokens.asyncMessages.FileCreationInProgress]:
    'La génération du fichier CSV est en cours. Celui-ci sera disponible dans la liste des exports une fois terminé.',
  [tokens.asyncMessages.CSVImportFailed]: 'La récupération des fichiers déjà exportés a échoué',
  [tokens.asyncMessages.FileRetryFailed]: 'La tentative de regénération du fichier a échoué',
  [tokens.asyncMessages.exportError]: "Erreur lors de l'export:",
  [tokens.filters.status.initial]: 'Invitation envoyée',
  [tokens.filters.status.initialMembership]: 'En attente de validation président',
  [tokens.filters.status.active]: 'Validé',
  [tokens.filters.status.inactive]: 'Désactivé',
  [tokens.filters.status.declined]: 'Refusé',
  [tokens.filters.status.voided]: 'Annulé',
  [tokens.filters.status.userAccepted]: 'Accepté par le membre',
  [tokens.filters.status.userRefused]: 'Refusé par le membre',
  [tokens.filters.status.presidentAccepted]: 'Accepté par le Président',
  [tokens.filters.status.presidentRefused]: 'Refusé par le Président',
  [tokens.filters.status.pendingSign]: 'En cours de signature',
  [tokens.filters.status.waitingSign]: 'En attente de signature',
  [tokens.filters.status.expired]: 'Expiré',
  [tokens.filters.status.guestAccepted]: "Accepté par l'invité",
  [tokens.filters.status.guestRefused]: "Refusé par l'invité",
  [tokens.filters.status.aborted]: 'Annulé',
  [tokens.filters.status.signed]: 'Signé',
  [tokens.filters.status.submitted]: 'À valider par le Président',
  [tokens.filters.format.fortnightly]: 'Développement',
  [tokens.filters.format.monthly]: 'Convivial',
  [tokens.filters.format.weekly]: 'Intensif',
  [tokens.filters.type.president]: 'Président',
  [tokens.filters.type.member]: 'Membre',
  [tokens.filters.type.foundingMember]: 'Membre fondateur',
  [tokens.filters.active.isActive]: 'Oui',
  [tokens.filters.active.isNotActive]: 'Non',
  [tokens.filters.resetFilters]: 'Réinitialiser les filtres',
  [tokens.domains.clubs.list.grid.noResult]: 'Aucun club ne correspond aux filtres sélectionnés',
  [tokens.domains.members.list.grid.noResult]:
    'Aucun membre ne correspond aux filtres sélectionnés',
  [tokens.domains.registrations.list.grid.noResult]:
    'Aucune adhésion ne correspond aux filtres sélectionnés',
  [tokens.domains.helpdesk.list.grid.noResult]:
    'Aucune demande de support ne correspond aux filtres sélectionnés',
  [tokens.domains.users.list.grid.noResult]:
    'Aucun utilisateur ne correspond aux filtres sélectionnés',
  [tokens.domains.clubs.clubHeaderTitle]: 'Liste des clubs',
  [tokens.domains.members.memberHeaderTitle]: 'Liste des membres',
  [tokens.domains.helpdesk.helpdeskHeaderTitle]: 'Liste des demandes',
  [tokens.domains.clubs.createClub]: 'Créer un club',
  [tokens.domains.registrations.registrationsHeaderTitle]: 'Liste des adhésions',
  [tokens.domains.settings.settingsHeaderTitle]: 'Mes paramètres',
  [tokens.domains.settings.personalInfos.detail.generalInfos]: 'Informations générales',
  [tokens.domains.settings.personalInfos.detail.invalidPassword]:
    'Le mot de passe actuel est incorrect',
  [tokens.domains.settings.personalInfos.detail.uploadProfilePicture]:
    'Mise à jour de la photo de profil',
  [tokens.domains.settings.personalInfos.detail.save]: 'Sauvegarder',
  [tokens.domains.settings.personalInfos.detail.validate]: 'Valider',
  [tokens.domains.settings.personalInfos.detail.cancel]: 'Annuler',
  [tokens.domains.settings.personalInfos.detail.clickOrDrag]: 'Cliquer ou glisser une photo',
  [tokens.domains.settings.personalInfos.detail.maxSize]: '(jpg, png, 3Mo max)',
  [tokens.domains.settings.personalInfos.detail.emailSupport]:
    'Merci de contacter le support pour changer votre email',
  [tokens.domains.settings.personalInfos.detail.updatePictureFailed]:
    'Une erreur est survenue lors de la mise à jour de votre photo de profil : ',
  [tokens.domains.settings.personalInfos.detail.passwordReset]: 'Changer de mot de passe',
  [tokens.domains.settings.personalInfos.detail.currentPassword]: 'Mot de passe actuel',
  [tokens.domains.settings.personalInfos.detail.newPassword]: 'Nouveau mot de passe',
  [tokens.domains.settings.personalInfos.detail.confirmPassword]:
    'Validation du nouveau mot de passe',
  [tokens.domains.settings.personalInfos.detail.passwordChangedSuccess]:
    'Le mot de passe a été modifié avec succès',
  [tokens.domains.settings.personalInfos.detail.passwordChangedFail]:
    'Une erreur est survenue lors de la modification du mot de passe',
  [tokens.domains.users.usersHeaderTitle]: "Liste des utilisateurs club d'affaires",
  [tokens.seo.club.title]: 'Liste des clubs',
  [tokens.seo.member.title]: 'Liste des membres',
  [tokens.seo.helpdesk.title]: 'Liste des demandes',
  [tokens.seo.registrations.title]: 'Liste des adhésions',
  [tokens.seo.settings.title]: 'Mes paramètres',
  [tokens.seo.user.title]: "Liste des utilisateurs club d'affaires",
  [tokens.headerColumnReactAdmin.clubName]: 'NOM DU CLUB',
  [tokens.headerColumnReactAdmin.president]: 'PRÉSIDENT',
  [tokens.headerColumnReactAdmin.agency]: 'AGENCE',
  [tokens.headerColumnReactAdmin.format]: 'FORMAT',
  [tokens.headerColumnReactAdmin.department]: 'DÉPARTEMENT',
  [tokens.headerColumnReactAdmin.status]: 'STATUT',
  [tokens.headerColumnReactAdmin.members]: 'MEMBRES',
  [tokens.headerColumnReactAdmin.createdAt]: 'CRÉÉ LE',
  [tokens.headerColumnReactAdmin.modifyAt]: 'DATE DE MODIFICATION DU STATUT',
  [tokens.headerColumnReactAdmin.action]: 'ACTION',
  [tokens.headerColumnReactAdmin.memberName]: 'NOM DU MEMBRE',
  [tokens.headerColumnReactAdmin.organization]: 'ENTREPRISE',
  [tokens.headerColumnReactAdmin.payment]: 'PAIEMENT',
  [tokens.headerColumnReactAdmin.signedAt]: 'Signé le',
  [tokens.headerColumnReactAdmin.disabledAt]: 'Désactivé le',
  [tokens.headerColumnReactAdmin.isActive]: 'Actif',
  [tokens.headerColumnReactAdmin.role]: 'Role',
  [tokens.headerColumnReactAdmin.type]: 'TYPE',
  [tokens.headerColumnReactAdmin.invitedBy]: 'Invité par',
  [tokens.headerColumnReactAdmin.dateDiscoveryMeeting]: 'Date réunion découverte',
  [tokens.headerColumnReactAdmin.guestName]: "Nom de l'invité",
  [tokens.placeholder.globalSearch]: 'Recherche',
  [tokens.placeholder.clubNameSearch]: 'Recherche par nom de club',
  [tokens.placeholder.agencySearch]: 'Rechercher une agence',
  [tokens.placeholder.adminUserSearch]: 'Rechercher un utilisateur',
  [tokens.placeholder.memberSearch]: 'Rechercher un membre',
  [tokens.placeholder.memberSearchByName]: 'Rechercher un membre par nom/club',
  [tokens.placeholder.clubSearch]: 'Rechercher un club',
  [tokens.placeholder.organizationSearch]: 'Rechercher une entreprise',
  [tokens.placeholder.clubInvitationsSearch]: "Rechercher par nom de l'invité/entreprise",
  [tokens.domains.clubs.results]: 'Résultats',
  [tokens.breadcrumb.memberList]: 'Liste des membres',
  [tokens.breadcrumb.clubList]: 'Liste des clubs',
  [tokens.breadcrumb.helpdeskList]: 'Liste des tickets',
  [tokens.domains.members.detail.memberCardTitle]: 'Informations Membre',
  [tokens.domains.members.detail.disableMemberCardTitle]: 'Désactiver le membre',
  [tokens.domains.members.detail.disableMemberCardDescription]:
    'Une fois désactivé le membre n’aura plus accès à son espace club et n’apparaitra plus sur le site vitrine Club d’Affaires. ',
  [tokens.domains.members.detail.disableMemberCardDescriptionModal]:
    'Désactiver le membre aura pour conséquence qu’il n’aura plus accès à l’espace Club d’Affaires du Club {{NOM_DU_CLUB}}. Il sera possible si besoin de lui créer un nouveau contrat au sein du club. Un email sera envoyé au membre pour l’avertir de sa désactivation sur le club. ',
  [tokens.domains.members.detail.organizationCardTitle]: 'Informations Entreprise',
  [tokens.domains.members.detail.ClubCardTitle]: 'Informations Club',
  [tokens.domains.members.detail.label.name]: 'Nom',
  [tokens.domains.members.detail.label.firstName]: 'Prénom',
  [tokens.domains.members.detail.label.lastName]: 'Nom',
  [tokens.domains.members.detail.label.mail]: 'Adresse mail',
  [tokens.domains.members.detail.label.phone]: 'Téléphone',
  [tokens.domains.members.detail.label.job]: 'Fonction',
  [tokens.domains.members.detail.label.corporateName]: 'Raison sociale',
  [tokens.domains.members.detail.label.siret]: 'Siret',
  [tokens.domains.members.detail.label.rna]: 'RNA',
  [tokens.domains.members.detail.label.brand]: 'Nom commercial',
  [tokens.domains.members.detail.label.fullAddress]: 'Adresse',
  [tokens.domains.members.detail.label.capital]: 'Capital',
  [tokens.domains.members.detail.label.activity]: 'Activité',
  [tokens.domains.members.detail.label.website]: 'Site web',
  [tokens.domains.members.detail.label.size]: 'Effectif',
  [tokens.domains.members.detail.label.legalForm]: 'Forme juridique',
  [tokens.domains.members.detail.label.clubName]: 'Nom du club',
  [tokens.domains.members.detail.label.debitAmount]: 'Montant du prélèvement',
  [tokens.domains.members.detail.label.accountCreationDate]: 'Date de création de compte',
  [tokens.domains.members.detail.label.format]: 'Format',
  [tokens.domains.members.detail.label.agency]: 'Agence',
  [tokens.domains.members.detail.label.subAgency]: 'Sous-agence',
  [tokens.domains.members.detail.label.type]: 'Type',
  [tokens.domains.members.detail.label.paymentFrequency]: 'Fréquence de paiement',
  [tokens.domains.members.detail.label.contractSignatureDate]: 'Date de signature du contrat',
  [tokens.domains.members.detail.label.membershipContractSignatory]:
    "Signataire du contrat d'adhésion",
  [tokens.domains.members.detail.label.methodOfPayment]: 'Mode de paiement',
  [tokens.domains.members.detail.label.contract]: 'Contrat',
  [tokens.domains.members.detail.label.signatoryAgency]: 'Agence signataire',
  [tokens.domains.members.detail.label.attachmentAgency]: 'Agence de rattachement',
  [tokens.domains.members.detail.label.clubContract]: 'Contrat du club',
  [tokens.domains.members.detail.label.createdAt]: 'Créé',
  [tokens.domains.members.detail.label.signedAt]: 'Signé',
  [tokens.domains.members.detail.label.disabledAt]: 'Désactivé',
  [tokens.domains.members.detail.label.clubAddress]: 'Adresse du club',
  [tokens.domains.members.detail.label.meetings]: 'Réunions',
  [tokens.domains.members.detail.label.companyName]: 'Raison sociale',
  [tokens.domains.members.detail.alertMessage.invalidSiret]: 'Le SIRET doit posséder 14 chiffres.',
  [tokens.domains.members.detail.alertMessage.invalidRna]:
    'Le RNA doit commencer par "W" suivi de 9 chiffres.',
  [tokens.domains.members.detail.notify.success]: 'Le membre a été modifié avec succès',
  [tokens.domains.members.detail.notify.fail]: 'Erreur lors de la modification du membre',
  [tokens.domains.members.detail.notify.notUpdated]: "Le membre n'a pas été modifié",
  [tokens.domains.clubs.detail.notify.success]: 'Le club a été modifié avec succès',
  [tokens.domains.clubs.detail.notify.fail]: 'Erreur lors de la modification du club',
  [tokens.common.button.validate]: 'Valider',
  [tokens.common.button.replace]: 'Remplacer',
  [tokens.common.button.remove]: 'Supprimer',
  [tokens.domains.clubs.detail.general]: 'Général',
  [tokens.domains.clubs.detail.memberships]: 'Adhésions',
  [tokens.domains.clubs.detail.membershipsStats.pendingSignature]: 'En cours de signature',
  [tokens.domains.clubs.detail.membershipsStats.pendingValidation]: 'Accepté par le membre',
  [tokens.domains.clubs.detail.membershipsStats.prospectDeclined]: 'Refusé par le membre',
  [tokens.domains.clubs.detail.membershipsStats.presidentDeclined]: 'Refusé par le Président',
  [tokens.domains.clubs.detail.membershipsStats.expired]: 'Expiré',
  [tokens.domains.clubs.detail.invitations]: 'Invitations',
  [tokens.domains.clubs.detail.invitationsStats.aborted]: 'Annulé',
  [tokens.domains.clubs.detail.invitationsStats.submitted]: 'À valider par le Président',
  [tokens.domains.clubs.detail.invitationsStats.expired]: 'Expiré',
  [tokens.domains.clubs.detail.invitationsStats.pendingGuestAccepted]: "À valider par l'invité",
  [tokens.domains.clubs.detail.invitationsStats.guestDeclined]: 'Refusé par l’invité',
  [tokens.domains.clubs.detail.invitationsStats.presidentDeclined]: 'Refusé par le Président',
  [tokens.domains.clubs.detail.anonymized]: 'Anonymisé',
  [tokens.domains.clubs.detail.clubCardtitle]: 'Informations club',
  [tokens.domains.clubs.detail.presidentCardtitle]: 'Président',
  [tokens.domains.clubs.detail.practicalInfoCardtitle]: 'Informations pratiques',
  [tokens.domains.clubs.chips.download]: 'Télécharger',
  [tokens.domains.clubs.chips.see]: 'Voir',
  [tokens.domains.clubs.chips.disable]: 'Désactiver',
  [tokens.domains.helpdesk.list.filters.status.created]: 'Créé',
  [tokens.domains.helpdesk.list.filters.status.in_progress]: 'En cours',
  [tokens.domains.helpdesk.list.filters.status.done]: 'Terminé',
  [tokens.domains.helpdesk.list.filters.status.aborted]: 'Annulé',
  [tokens.domains.helpdesk.list.filters.type.public]: 'Non connecté',
  [tokens.domains.helpdesk.list.filters.type.user]: 'Connecté',
  [tokens.domains.helpdesk.list.grid.company]: 'Entreprise',
  [tokens.domains.helpdesk.list.grid.type]: 'Type',
  [tokens.domains.helpdesk.list.grid.topic]: 'Motif',
  [tokens.domains.helpdesk.list.grid.manager]: 'Responsable',
  [tokens.domains.helpdesk.list.grid.department]: 'Département',
  [tokens.domains.helpdesk.list.grid.createdAt]: 'Création',
  [tokens.domains.helpdesk.detail.info.title]: 'Information demande',
  [tokens.domains.helpdesk.detail.info.nature]: 'Nature',
  [tokens.domains.helpdesk.detail.info.status]: 'Statut',
  [tokens.domains.helpdesk.detail.info.date]: 'Date',
  [tokens.domains.helpdesk.detail.info.manager]: 'Responsable',
  [tokens.domains.helpdesk.detail.info.club]: 'Club',
  [tokens.domains.helpdesk.detail.applicant.title]: 'Demandeur',
  [tokens.domains.helpdesk.detail.applicant.fullname]: 'Prénom nom',
  [tokens.domains.helpdesk.detail.applicant.job]: 'Fonction',
  [tokens.domains.helpdesk.detail.applicant.organization]: 'Entreprise',
  [tokens.domains.helpdesk.detail.applicant.phone]: 'Numéro de téléphone',
  [tokens.domains.helpdesk.detail.applicant.email]: 'E-mail',
  [tokens.domains.helpdesk.detail.applicant.department]: 'Département',
  [tokens.domains.helpdesk.detail.message.title]: 'Message',
  [tokens.domains.helpdesk.detail.notify.fail]: 'Erreur lors de la modification de la demande',
  [tokens.domains.helpdesk.detail.notify.success]: 'La demande a été modifiée avec succès',
  [tokens.domains.helpdesk.detail.notify.notUpdated]: "La demande n'a pas été modifiée",
  [tokens.utils.isValidSiret.siretRequired]: 'Le SIRET est requis',
  [tokens.utils.isValidSiret.siretInvalid]: 'SIRET non valide, doit contenir 14 chiffres.',
  [tokens.utils.isValidSiret.siretFormatInvalid]: 'Le format du SIRET est invalide.',
  [tokens.utils.isValidSiret.updatedSirenNotAllowed]: "La modification du SIREN n'est pas permise",
  [tokens.utils.isValidRna.rnaRequired]: 'Le RNA est requis',
  [tokens.utils.isValidRna.rnaInvalid]: 'RNA non valide, doit commencer par W suivi de 9 chiffres.',
  [tokens.utils.days.monday]: 'lundi',
  [tokens.utils.days.tuesday]: 'mardi',
  [tokens.utils.days.wednesday]: 'mercredi',
  [tokens.utils.days.thursday]: 'jeudi',
  [tokens.utils.days.friday]: 'vendredi',
  [tokens.utils.days.saturday]: 'samedi',
  [tokens.utils.days.sunday]: 'dimanche',
  [tokens.utils.format.fortnightly]: 'tous les 15 jours',
  [tokens.utils.format.monthly]: 'une fois par mois',
  [tokens.utils.format.weekly]: 'chaque semaine',
  [tokens.common.type.president]: 'Président',
  [tokens.common.type.member]: 'Membre',
  [tokens.domains.members.filters.agencies]: 'Agences',
  [tokens.domains.members.filters.clubs]: 'Clubs',
  [tokens.domains.members.filters.organization]: 'Entreprises',
  [tokens.domains.members.filters.memberName]: 'Nom du membre',
  [tokens.domains.contract.stepper.title]: 'Etape de la création de contrat',
  [tokens.domains.contract.stepper.stepOne]: 'Informations sur le club',
  [tokens.domains.contract.stepper.stepTwo]: 'Informations sur le président',
  [tokens.domains.contract.stepper.stepThree]: 'Signature',
  [tokens.domains.contract.stepper.success]:
    'Toutes les étapes sont finalisées, le contrat a été créé avec succès.',
  [tokens.domains.contract.stepOne.presidentInfos.title]: 'Président',
  [tokens.domains.contract.stepOne.presidentInfos.cardTitle]:
    "Lier le club au compte d'un utilisateur club d'affaires",
  [tokens.domains.contract.stepOne.presidentInfos.cardSubtitle]:
    'Le président doit avoir un compte sur le site club d’affaires pour créer un contrat',
  [tokens.domains.contract.stepOne.presidentInfos.button]: 'Associer',
  [tokens.domains.contract.stepOne.generalInfos.title]: 'Informations générales',
  [tokens.domains.contract.stepOne.generalInfos.clubName.label]: 'Nom du club',
  [tokens.domains.contract.stepOne.generalInfos.clubName.placeholder]: 'Nom du club',
  [tokens.domains.contract.stepOne.generalInfos.presidentRemuneration.label]:
    'Taux de rémunération du président',
  [tokens.domains.contract.stepOne.generalInfos.presidentRemuneration.options.defaultOption]:
    'Sélectionnez un taux',
  [tokens.domains.contract.stepOne.generalInfos.siret.label]: 'N° SIRET du Président',
  [tokens.domains.contract.stepOne.generalInfos.siret.placeholder]: 'Saisissez le SIRET',
  [tokens.domains.contract.stepOne.generalInfos.rna.label]: 'RNA du Président',
  [tokens.domains.contract.stepOne.generalInfos.rna.placeholder]: 'Saisissez le RNA',
  [tokens.domains.contract.stepOne.meetings.title]: 'Réunions',
  [tokens.domains.contract.stepOne.meetings.format.title]: 'Format du club',
  [tokens.domains.contract.stepOne.meetings.format.description.monthly]:
    'Les réunions se déroulent une fois par mois.',
  [tokens.domains.contract.stepOne.meetings.format.description.fortnightly]:
    'Les réunions se déroulent une fois toutes les deux semaines.',
  [tokens.domains.contract.stepOne.meetings.format.description.weekly]:
    'Les réunions se déroulent une fois par semaine.',
  [tokens.domains.contract.stepOne.meetings.meetingDay.label]: 'Jour de la réunion',
  [tokens.domains.contract.stepOne.meetings.meetingDay.options.defaultOption]:
    'Sélectionnez la récurrence',
  [tokens.domains.contract.stepOne.meetings.firstMeetingDate.label]: 'Date de la première réunion',
  [tokens.domains.contract.stepOne.meetings.firstMeetingDate.placeholder]:
    'Sélectionnez le jour de la réunion',
  [tokens.domains.contract.stepOne.meetings.startingHour.label]: 'Heure de début',
  [tokens.domains.contract.stepOne.meetings.endingHour.label]: 'Heure de fin',
  [tokens.domains.contract.stepOne.meetingPlace.title]: 'Lieu des réunions',
  [tokens.domains.contract.stepOne.meetingPlace.fullAddress.label]: 'Adresse',
  [tokens.domains.contract.stepOne.meetingPlace.fullAddress.placeholder]:
    "Saisissez l'adresse du lieu",
  [tokens.domains.contract.stepOne.meetingPlace.meetingName.label]: 'Nom du lieu',
  [tokens.domains.contract.stepOne.meetingPlace.meetingName.placeholder]:
    'Saisissez le nom du lieu',
  [tokens.domains.contract.stepOne.meetingPlace.addressNotFound]: "Je ne trouve pas l'addresse",
  [tokens.domains.contract.stepOne.meetingPlace.address.label]: 'Adresse',
  [tokens.domains.contract.stepOne.meetingPlace.address.placeholder]: "Saisissez l'adresse",
  [tokens.domains.contract.stepOne.meetingPlace.postalCode.label]: 'Code Postal',
  [tokens.domains.contract.stepOne.meetingPlace.postalCode.placeholder]: 'Saisissez le code postal',
  [tokens.domains.contract.stepOne.meetingPlace.city.label]: 'Ville',
  [tokens.domains.contract.stepOne.meetingPlace.city.placeholder]: 'Saisissez la ville',
  [tokens.domains.contract.stepOne.meetingPlace.country.label]: 'Pays',
  [tokens.domains.contract.stepOne.meetingPlace.country.placeholder]: 'Saissisez le pays',
  [tokens.domains.contract.stepOne.meetingPlace.country.options.defaultOption]:
    'Sélectionnez le pays',
  [tokens.domains.contract.stepOne.meetingPlace.closestAddress.label]: 'Adresse la plus proche',
  [tokens.domains.contract.stepOne.meetingPlace.closestAddress.placeholder]:
    'Saisissez une adresse proche du lieu',
  [tokens.domains.contract.stepTwo.companyInfos.title]: 'Informations sur l’entreprise',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.label]: 'Format juridique',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.options.defaultOption]:
    'Sélectionnez le format',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.options.individual]:
    'Entreprise individuelle',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.options.EIRL]: 'EIRL',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.options.EURL]: 'EURL',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SARL]: 'SARL',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SASU]: 'SASU',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SAS]: 'SAS',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SA]: 'SA',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SCS]: 'SCS',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SCA]: 'SCA',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.options.SNC]: 'SNC',
  [tokens.domains.contract.stepTwo.companyInfos.legalForm.options.association]: 'Association',
  [tokens.domains.contract.stepTwo.companyInfos.name.label]: 'Raison sociale',
  [tokens.domains.contract.stepTwo.companyInfos.name.placeholder]: 'Saisissez la raison sociale',
  [tokens.domains.contract.stepTwo.companyInfos.brand.label]: 'Nom commercial si différent',
  [tokens.domains.contract.stepTwo.companyInfos.brand.placeholder]: 'Saissisez le nom commercial',
  [tokens.domains.contract.stepTwo.companyInfos.rcs.label]: "Ville d'immatriculation au RCS",
  [tokens.domains.contract.stepTwo.companyInfos.rcs.placeholder]:
    "Saisissez la ville d'immatriculation au RCS",
  [tokens.domains.contract.stepTwo.companyInfos.activity.label]: 'Activité',
  [tokens.domains.contract.stepTwo.companyInfos.activity.placeholder]: 'Saisissez l’activité',
  [tokens.domains.contract.stepTwo.companyInfos.compagnyAddress.label]: 'Adresse',
  [tokens.domains.contract.stepTwo.companyInfos.compagnyAddress.placeholder]: 'Saisissez l’adresse',
  [tokens.domains.contract.stepTwo.companyInfos.compagnyPostalCode.label]: 'Code postal',
  [tokens.domains.contract.stepTwo.companyInfos.compagnyPostalCode.placeholder]:
    'Saisissez l’adresse',
  [tokens.domains.contract.stepTwo.companyInfos.compagnyCity.label]: 'Ville',
  [tokens.domains.contract.stepTwo.companyInfos.compagnyCity.placeholder]: 'Saisissez la ville',
  [tokens.domains.contract.stepTwo.companyInfos.compagnyCountry.label]: 'Pays',
  [tokens.domains.contract.stepTwo.companyInfos.compagnyCountry.placeholder]: 'Saisissez le pays',
  [tokens.domains.contract.stepTwo.companyInfos.compagnySize.label]: 'Effectif',
  [tokens.domains.contract.stepTwo.companyInfos.compagnySize.placeholder]: "Saisissez l'effectif",
  [tokens.domains.contract.stepTwo.companyInfos.compagnySize.tooltip]:
    'L"effectif comprend les TNS (Travailleurs Non Salariés + Salariés + Alternants) Sont exclus les intérimaires, les prestataires externes, les stagiaires,...',
  [tokens.domains.contract.stepTwo.companyInfos.compagnyCapital.label]: 'Capital',
  [tokens.domains.contract.stepTwo.companyInfos.compagnyCapital.placeholder]:
    'Saisissez le capital',
  [tokens.domains.contract.stepTwo.signatory.title]: 'Coordonnées du président',
  [tokens.domains.contract.stepTwo.signatory.civility.label]: 'Civilité',
  [tokens.domains.contract.stepTwo.signatory.civility.options.defaultOption]: 'Civilité',
  [tokens.domains.contract.stepTwo.signatory.civility.options.miss]: 'Madame',
  [tokens.domains.contract.stepTwo.signatory.civility.options.mister]: 'Monsieur',
  [tokens.domains.contract.stepTwo.signatory.recurring.label]: 'Jour de la réunion',
  [tokens.domains.contract.stepTwo.signatory.recurring.options.defaultOption]:
    'Sélectionnez la récurrence',
  [tokens.domains.contract.stepTwo.signatory.signatoryFirstName.label]: 'Prénom',
  [tokens.domains.contract.stepTwo.signatory.signatoryFirstName.placeholder]: 'Saisissez le prénom',
  [tokens.domains.contract.stepTwo.signatory.signatoryLastName.label]: 'Nom',
  [tokens.domains.contract.stepTwo.signatory.signatoryLastName.placeholder]: 'Saisissez le nom',
  [tokens.domains.contract.stepTwo.signatory.signatoryEmail.label]: 'Adresse mail',
  [tokens.domains.contract.stepTwo.signatory.signatoryEmail.placeholder]:
    'Saisissez l’adresse mail',
  [tokens.domains.contract.stepTwo.signatory.signatoryEmail.emailVisibility]:
    "Accepter que l'adresse mail du président soit visible sur le site www.clubdaffaires.fr",
  [tokens.domains.contract.stepTwo.signatory.signatoryPhone.label]: 'Téléphone',
  [tokens.domains.contract.stepTwo.signatory.signatoryPhone.placeholder]: 'Saisissez le téléphone',
  [tokens.domains.contract.stepTwo.signatory.signatoryPhone.phoneVisibility]:
    'Accepter que le téléphone du président soit visible sur le site www.clubdaffaires.fr',
  [tokens.domains.contract.validators.siretOrRna]: 'Ce champ, ou celui du RNA, est requis',
  [tokens.domains.contract.validators.rnaOrSiret]: 'Ce champ, ou celui du SIRET, est requis',
  [tokens.domains.contract.stepThree.title]: 'Contrat envoyé pour signatures',
  [tokens.domains.contract.stepThree.typoOne]:
    'Le contrat vient d’être envoyé par email au dirigeant et au directeur d’agence pour signature.',
  [tokens.domains.contract.stepThree.typoTwo]:
    'Vous pourrez suivre son évolution depuis la vue “Clubs”.',
  [tokens.domains.contract.stepThree.typoThree]:
    'Un email vous sera envoyé dès que le contrat sera signé par les deux parties.',
  [tokens.domains.contract.stepThree.button]: 'Créer un nouveau contrat',
  [tokens.utils.step.next]: 'Etape suivante',
  [tokens.utils.step.previous]: 'Etape précédente',
  [tokens.utils.form.required]: 'Ce champ est requis',
  [tokens.utils.form.maxLength]: 'Le mot ne doit pas dépasser 50 caractères.',
  [tokens.utils.form.minLength]: 'Le mot doit faire au moins 2 caractères.',
  [tokens.domains.contract.validators.invalidPostalcode]: 'Code postal invalide',
  [tokens.domains.contract.validators.startEventNotMatchingDayWeek]:
    'Le jour sélectionné ne correspond pas au jour de la réunion',
  [tokens.utils.form.endHourBeforeStartHour]:
    "L'heure de fin doit être supérieure à l'heure de début",
  [tokens.utils.form.invalidEmail]: "L'email est invalide",
  [tokens.utils.form.invalidPassword]:
    'Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial',
  [tokens.utils.form.passwordNotMatch]: 'Les mots de passe ne correspondent pas',
  [tokens.domains.contract.modal.title]: 'Signature du contrat',
  [tokens.domains.contract.modal.description]:
    'Vous êtes sur le point de valider et d’envoyer le contrat pour signature. Validez-vous toutes les informations renseignées ?',
  [tokens.domains.contract.modal.cancel]: 'Annuler',
  [tokens.domains.contract.modal.confirm]: 'Valider et Envoyer',
  [tokens.domains.contract.modal.fail]:
    "Une erreur est survenue, la création du contrat n'a pu aboutir.",
  [tokens.utils.country.france]: 'france',
  [tokens.utils.country.spain]: 'espagne',
  [tokens.utils.country.switzerland]: 'suisse',
  [tokens.utils.form.mustBeAnInteger]: 'Ce champ doit être un nombre entier',
  [tokens.utils.form.mustBeANumber]: 'Ce champ doit être un nombre.',
  [tokens.utils.form.maxLengthExceeded]: 'Le nombre ne doit pas dépasser {{maxLength}} caractères.',
  [tokens.nav.club.contracts.create]: 'Contrats',
  [tokens.domains.contract.contractHeaderTitle]: "Création de contrat Club d'Affaires",
  [tokens.domains.contract.info]:
    'Le Président doit avoir un compte sur le site <0>club d’affaires</0> pour pouvoir créer un Contrat.',

  [tokens.utils.form.noOptionsText]: 'Aucune option disponible',
  [tokens.utils.error.unknown]: 'Une erreur inconnue est survenue',
  [tokens.utils.form.selectElementFromList]: 'Veuillez sélectionner un élément de la liste',
  [tokens.utils.form.errorOccured]: 'Une erreur est survenue.',
  [tokens.utils.maintenance.title]: 'Site en cours de maintenance',
  [tokens.utils.maintenance.description]: 'Nous serons en ligne sous peu.',
  [tokens.settingsDrawer.title]: 'Paramètres',
  [tokens.settingsDrawer.color.title]: 'Couleur principale',
  [tokens.settingsDrawer.color.blue]: 'Bleu',
  [tokens.settingsDrawer.color.green]: 'Vert',
  [tokens.settingsDrawer.color.indigo]: 'Indigo',
  [tokens.settingsDrawer.color.purple]: 'Violet',
  [tokens.settingsDrawer.mode.title]: "Mode d'affichage",
  [tokens.settingsDrawer.mode.light]: 'Lumineux',
  [tokens.settingsDrawer.mode.dark]: 'Sombre',
  [tokens.settingsDrawer.nav.title]: 'Effet panneau latéral',
  [tokens.settingsDrawer.nav.blendIn]: 'Fondu',
  [tokens.settingsDrawer.nav.discrete]: 'Subtil',
  [tokens.settingsDrawer.nav.evident]: 'Apparent',
  [tokens.settingsDrawer.contrast.title]: 'Contraste',
  [tokens.settingsDrawer.contrast.normal]: 'Normal',
  [tokens.settingsDrawer.contrast.high]: 'Élevé',
  [tokens.dataProvider.contract.error]:
    "Une erreur s'est produite lors de la tentative de création du contrat.",
  [tokens.dataProvider.contract.error500]:
    'Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.',
  [tokens.dataProvider.export.downloadError]:
    "Une erreur s'est produite lors de la création de l'export.",
  [tokens.dataProvider.export.cancelError]:
    'Une erreur s’est produite lors de la tentative d’annulation de l’export.',
  [tokens.dataProvider.export.retryError]:
    "Une erreur s'est produite lors de la tentative de reprise de l'export.",
  [tokens.dataProvider.export.postError]:
    "Une erreur s'est produite lors de la tentative de création de l'export.",
  [tokens.dataProvider.picture.postError]:
    "Une erreur s'est produite lors de l'upload de l'image de profil.",
  [tokens.dataProvider.picture.deleteError]:
    "Une erreur s'est produite lors de la tentative de suppression de l'image de profil.",
  [tokens.dataProvider.user.postError]:
    "Une erreur s'est produite lors de la tentative de mise à jour des informations personnelles.",
  [tokens.modale.disableMember.title]: 'Désactivation du membre',
  [tokens.modale.disableMember.description]:
    'Vous êtes sur le point de désactiver le membre. Validez-vous toutes les informations renseignées ?',
  [tokens.modale.disableMember.cancel]: 'Annuler',
  [tokens.modale.disableMember.confirm]: 'Valider et Désactiver',
  [tokens.modale.disableMember.fail]:
    "Une erreur est survenue, la désactivation du membre n'a pu aboutir.",
  [tokens.modale.disableMember.noMatch]:
    'Le nom du membre saisi ne correspond pas au nom du membre',
  [tokens.modale.disableMember.name]: 'Saisir le prénom et le nom du membre',
  [tokens.modale.disableMember.disableError]:
    'Une erreur est survenue lors de la tentative de désactivation du membre.',
  [tokens.modale.disableMember.disableMemberScheduledDescription]:
    'Le membre sera désactivé le {{DATE}}',
};
