import { Typography } from '@mui/material';
import Stack from '@mui/system/Stack';
import { t } from 'i18next';
import { DateField, FunctionField, TextField } from 'react-admin';
import { ColumnHeaderLabel } from 'src/common/enums/react-admin';
import { DatagridLayout } from 'src/common/layouts/react-admin';
import { MemberSortByEnum } from 'src/data/enum/local/orderFilterEnum';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { tokens } from 'src/locales/tokens';
import { UserRole } from 'src/types/enums/user-roles-enum';

import { UserColumnSourceEnum } from '../enums/column-source-enum';

type ClubUserType = {
  organization: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createdAt: string;
};

export const ClubUserGrid = () => {
  return (
    <DatagridLayout content={t(tokens.domains.users.list.grid.noResult)}>
      <FunctionField
        sortBy={MemberSortByEnum.MEMBER_NAME}
        label={ColumnHeaderLabel.memberName}
        render={(record: ClubUserType) => {
          return (
            <Stack>
              <Typography sx={{ fontSize: '0.875rem' }}>
                {record.firstName} {record.lastName?.toUpperCase()}
              </Typography>
              <TextField
                source={'email'}
                variant={'caption'}
                sx={{ fontSize: '12px', color: 'text.secondary' }}
              />
              <TextField
                source={'phone'}
                variant={'caption'}
                sx={{ fontSize: '12px', color: 'text.secondary' }}
              />
            </Stack>
          );
        }}
      ></FunctionField>
      <TextField
        sortBy={MemberSortByEnum.ORGANIZATION_NAME}
        label={ColumnHeaderLabel.organization}
        source={UserColumnSourceEnum.ORGANIZATION}
      />
      <DateField
        sortBy={MemberSortByEnum.CREATED_AT}
        label={ColumnHeaderLabel.createdAt}
        source={UserColumnSourceEnum.CREATED_AT}
      />
    </DatagridLayout>
  );
};
