import { t } from 'i18next';
import { ValidationError } from 'src/domains/club/contract/context';
import { tokens } from 'src/locales/tokens';
import { User } from 'src/types/contexts/auth';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validateEmail = (values: Partial<User>, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.email)) {
    errors.email = t(tokens.utils.form.required);
  } else if (values?.email && values.email.length > 50) {
    errors.email = t(tokens.utils.form.maxLength, { length: 50 });
  } else if (values?.email && !emailRegex.test(values.email)) {
    errors.email = t(tokens.utils.form.invalidEmail);
  }
};
