import Chip from '@mui/material/Chip';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';

interface HelpdeskTicketStatusChipProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  disabled: boolean;
  status: {
    color: {
      background: string;
      text: string;
    };
    label: string;
  };
}

const HelpdeskTicketStatusChipField: ForwardRefRenderFunction<
  HTMLDivElement,
  HelpdeskTicketStatusChipProps
> = function HelpdeskTicketStatusChip({ status, onClick, ...props }, ref) {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClick(event);
  };

  return (
    <Chip
      ref={ref}
      sx={{
        width: '100%',
        bgcolor: status.color.background,
        color: status.color.text,
        fontWeight: 'bold',
      }}
      label={status.label.toUpperCase()}
      onClick={handleClick}
      {...props}
    />
  );
};

export default forwardRef(HelpdeskTicketStatusChipField);
