import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import { t } from 'i18next';
import { ImageInput, ImageField } from 'react-admin';
import { tokens } from 'src/locales/tokens';

import { DropZone } from './DropZone';

type UpdateProfilePictureProps = {
  open: boolean;
  handleSave: (picture: File) => Promise<void>;
  handleClose: () => void;
  resetError: () => void;
  handleChange: (file: File) => void;
  handleRemove: () => Promise<void>;
  error: string | null;
  picture: File | null;
  pictureLoaded: boolean;
};

export const UpdateProfilePicture = ({
  open,
  error,
  picture,
  pictureLoaded,
  handleSave,
  handleClose,
  handleChange,
  handleRemove,
}: UpdateProfilePictureProps) => {
  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          width: '700px',
        },
      }}
    >
      <CloseIcon
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 5,
          top: 5,
          cursor: 'pointer',
          color: 'grey.700',
          borderRadius: '50%',
          margin: 1.5,
          padding: 0.5,
        }}
      />
      <Box sx={{ px: 3.5, pt: 6, pb: 2, textAlign: 'center' }}>
        <DialogTitle>
          {t(tokens.domains.settings.personalInfos.detail.uploadProfilePicture)}
        </DialogTitle>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          px: 3.5,
          py: 2,
        }}
      >
        <ImageInput
          source="image"
          accept=".jpg"
          maxSize={3000000}
          onChange={(file: File) => handleChange(file)}
          onRemove={() => handleRemove()}
          placeholder={<DropZone />}
          sx={{
            textAlign: 'center',
          }}
        >
          <ImageField
            source="src"
            title="photo de profil"
          />
        </ImageInput>
      </Box>

      {error && (
        <Box sx={{ px: 3.5, pb: 2, textAlign: 'center' }}>
          <Typography
            color="error"
            sx={{ fontWeight: 'bold' }}
          >
            {error}
          </Typography>
        </Box>
      )}
      <DialogActions>
        <Box
          sx={{ px: 1, pb: 1, width: '100%', display: 'flex', gap: 1, justifyContent: 'center' }}
        >
          <Button
            onClick={() => handleClose()}
            variant="outlined"
            type="submit"
            disabled={!pictureLoaded}
          >
            {t(tokens.domains.settings.personalInfos.detail.cancel)}
          </Button>
          <Button
            onClick={() => handleSave(picture as File)}
            variant="contained"
            type="submit"
            disabled={!pictureLoaded}
          >
            {t(tokens.domains.settings.personalInfos.detail.save)}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
