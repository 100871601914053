import Stack from '@mui/system/Stack';
import { Datagrid, useListContext } from 'react-admin';
import { EmptyResult } from 'src/common/components/grid/EmptyResult';
import { useCssVars } from 'src/hooks/theme/useCssVarsDesktop';
import { useSettings } from 'src/hooks/useSettings';

interface DatagridLayout {
  children: React.ReactNode[];
  skeleton?: React.FC;
  content?: React.ReactNode;
}

export const DatagridLayout: React.FC<DatagridLayout> = ({
  children,
  skeleton: Skeleton,
  content,
}) => {
  const { isFetching, isLoading } = useListContext();
  const { navColor: color } = useSettings();

  const cssVars = useCssVars(color);
  return (
    <Stack
      spacing={2}
      sx={{
        ...cssVars,
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '0px',
          background: 'transparent',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'var(--nav-scrollbar-color)',
          borderRadius: '2px',
          opacity: 0.5,
        },
        '&::-webkit-scrollbar-button': {
          display: 'none',
        },
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgba(var(--nav-scrollbar-color-rgb), 0.5) transparent',
      }}
    >
      {(!isFetching && !isLoading) || Skeleton === undefined ? (
        <Datagrid
          bulkActionButtons={false}
          empty={<EmptyResult {...{ content }} />}
        >
          {children}
        </Datagrid>
      ) : (
        <Skeleton />
      )}
    </Stack>
  );
};
