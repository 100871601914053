import { t } from 'i18next';
import { ValidationError } from 'src/domains/club/contract/context';
import { tokens } from 'src/locales/tokens';
import { isEmptyOrSpaces } from 'src/utils/isEmptyOrSpaces';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/;

export const validateCurrentPassword = (
  values: { currentPassword: string },
  errors: ValidationError
) => {
  if (isEmptyOrSpaces(values.currentPassword)) {
    errors.currentPassword = t(tokens.utils.form.required);
  }
};

export const validateNewPassword = (values: { newPassword: string }, errors: ValidationError) => {
  if (isEmptyOrSpaces(values.newPassword)) {
    errors.newPassword = t(tokens.utils.form.required);
  } else if (values.newPassword && !passwordRegex.test(values.newPassword)) {
    errors.newPassword = t(tokens.utils.form.invalidPassword);
  }
};

export const validateConfirmPassword = (
  values: { newPassword: string; confirmPassword: string },
  errors: ValidationError
) => {
  if (isEmptyOrSpaces(values.confirmPassword)) {
    errors.confirmPassword = t(tokens.utils.form.required);
  } else if (values.confirmPassword !== values.newPassword) {
    errors.confirmPassword = t(tokens.utils.form.passwordNotMatch);
  }
};
