export enum FilterTypes {
  SplitButton = 'splitButton',
  DatePicker = 'datePicker',
}

export enum FilterValues {
  FORMAT = 'format',
  STATUS = 'status',
  IS_ACTIVE = 'isActiveFilter',
  TYPE = 'type',
  MEMBER_TYPE = 'memberTypeFilter',
  CREATED_AFTER = 'createdAt[after]',
  CREATED_BEFORE = 'createdAt[before]',
  MODIFY_AT_AFTER = 'updatedAt[after]',
  MODIFY_AT_BEFORE = 'updatedAt[before]',
  EVENT_DATE_AFTER = 'eventMeeting.startDate[after]',
  EVENT_DATE_BEFORE = 'eventMeeting.startDate[before]',
}

export enum FilterLabels {
  Format = 'Format',
  Status = 'Statut',
  Active = 'Actif',
  Type = 'Type',
  CreatedAt = 'Date de début',
  EndAt = 'Date de fin',
}

export enum AddFilterType {
  enum = 'enum',
  date = 'date',
}
