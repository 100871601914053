enum MemberSearchFilterEnum {
  AGENCY = 'club.agencyId',
  SEARCH_TERM = 'searchTerm',
}

export enum ClubSearchFilterEnum {
  AGENCY = 'agencyId',
  SEARCH_TERM = 'searchTerm',
}

export enum RegistrationSearchFilterEnum {
  SEARCH_TERM = 'searchTerm',
}

export enum ClubInvitationsSearchFilterEnum {
  SEARCH_TERM = 'searchTerm',
}

export enum ClubUserSearchFilterEnum {
  SEARCH_TERM = 'searchTerm',
}

export default MemberSearchFilterEnum;
