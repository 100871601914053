import { t } from 'i18next';
import { DataProvider, fetchUtils, GetListParams } from 'react-admin';
import { resourceConfig } from 'src/config/resourceConfig';
import customDataProvider from 'src/data/provider/remote/hydra-provider';
import { apiDocumentationParser, fetchHydra, isErrorViolationType } from 'src/data/utils';
import { FinalData } from 'src/domains/club/contract/context';
import { DisableMemberModalPayload } from 'src/domains/club/members/components/DisableMemberModal';
import { tokens } from 'src/locales/tokens';
import { CustomDataProvider } from 'src/types/config';
import { FetchResponse, Violation } from 'src/types/config/errorViolationTypes';
import { User } from 'src/types/contexts/auth';
import { ListExport, ListExportRequest } from 'src/types/contexts/modals';
import { AccessRights } from 'src/types/pages/users';
import { getRequestOptions } from 'src/utils/getRequestOptions';
import { handleHttpError } from 'src/utils/handleHttpError';

export const ENTRYPOINT = import.meta.env.VITE_API_ENTRYPOINT;

const baseDataProvider: DataProvider = customDataProvider({
  entrypoint: ENTRYPOINT,
  httpClient: fetchHydra,
  apiDocumentationParser: apiDocumentationParser,
});

const dataProvider: CustomDataProvider = {
  ...baseDataProvider,
  getOne: (resource, params) =>
    baseDataProvider.getOne(resource, params).then(({ data }) => ({
      data: { ...data, id: data['@id'] as string },
    })),
  getList: (resource, params) => {
    const config = resourceConfig[resource];
    if (config) {
      let finalParams: GetListParams = { ...params };
      config.forEach((func) => {
        finalParams = func(finalParams);
      });

      return baseDataProvider.getList(resource, finalParams);
    }

    return baseDataProvider.getList(resource, params);
  },
  getAccessRights: async (userId: string) => {
    const url = `${ENTRYPOINT}/users/${userId}/access_rights`;
    try {
      const response = await fetchUtils.fetchJson(url, getRequestOptions('GET'));
      const accessRights = response.json['hydra:member'] as AccessRights;
      return accessRights;
    } catch (error) {
      throw error;
    }
  },
  updateAccessRights: async (userId: string, params: { accessRights: AccessRights }) => {
    const url = `${ENTRYPOINT}/users/${userId}/access_rights`;
    try {
      const response = await fetchUtils.fetchJson(
        url,
        getRequestOptions('PATCH', params, 'application/merge-patch+json')
      );
      const accessRights = response.json['hydra:member'] as AccessRights;
      return accessRights;
    } catch (error) {
      throw error;
    }
  },
  getExport: async (resource: string) => {
    const url = `${ENTRYPOINT}/${resource}/export/requests?order[createdAt]=desc`;
    try {
      const response = await fetchUtils.fetchJson(url, getRequestOptions('GET'));
      return response.json['hydra:member'] as ListExport[];
    } catch (error) {
      throw error;
    }
  },
  postClubExport: async (resource: string, params: ListExportRequest) => {
    const url = `${ENTRYPOINT}/${resource}/export/requests`;
    try {
      const response = await fetchUtils.fetchJson(
        url,
        getRequestOptions('POST', params, 'application/ld+json')
      );
      return response.json['hydra:member'] as ListExport;
    } catch (error: unknown) {
      if (error instanceof Error) {
        handleHttpError(error, t(tokens.dataProvider.export.postError));
      } else {
        handleHttpError(new Error('An error occurred'), t(tokens.dataProvider.export.postError));
      }
    }
  },
  retryClubExport: async (url: string) => {
    try {
      const response = await fetchUtils.fetchJson(
        url,
        getRequestOptions('PATCH', {}, 'application/merge-patch+json')
      );
      if (response.status >= 200 && response.status < 300) {
        console.log('Retry successful:', response.json);
        return response.json;
      } else {
        console.log('Unexpected status:', response.status);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        handleHttpError(error, t(tokens.dataProvider.export.retryError));
      } else {
        handleHttpError(new Error('An error occurred'), t(tokens.dataProvider.export.retryError));
      }
    }
  },
  cancelClubExport: async (url: string) => {
    try {
      const response = await fetchUtils.fetchJson(
        url,
        getRequestOptions('PATCH', {}, 'application/merge-patch+json')
      );
      if (response.status >= 200 && response.status < 300) {
        console.log('Retry successful:', response.json);
        return response.json;
      } else {
        console.log('Unexpected status:', response.status);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        handleHttpError(error, t(tokens.dataProvider.export.cancelError));
      } else {
        handleHttpError(new Error('An error occurred'), t(tokens.dataProvider.export.cancelError));
      }
    }
  },
  downloadExport: async (url: string) => {
    try {
      const response = await fetchUtils.fetchJson(url, getRequestOptions('GET'));
      return response.json['url'] as string;
    } catch (error: unknown) {
      if (error instanceof Error) {
        handleHttpError(error, t(tokens.dataProvider.export.downloadError));
      } else {
        handleHttpError(
          new Error('An error occurred'),
          t(tokens.dataProvider.export.downloadError)
        );
      }
    }
  },
  postContract: async (data: FinalData) => {
    const url = `${ENTRYPOINT}/contracts`;

    try {
      const response: FetchResponse = await fetchUtils.fetchJson(
        url,
        getRequestOptions('POST', data, 'application/ld+json')
      );

      if (response.status === 204) {
        return { status: 204, headers: response.headers, body: null };
      }

      return response;
    } catch (error) {
      if (isErrorViolationType(error)) {
        if (error.status === 422 && error.body?.violations) {
          const message = error.body.violations.map((violation: Violation) => violation.message);

          throw message;
        }
      }
      if (error.status === 500) {
        handleHttpError(error as Error, t(tokens.dataProvider.contract.error500));
        throw new Error(t(tokens.dataProvider.contract.error500));
      } else {
        handleHttpError(error as Error, t(tokens.dataProvider.contract.error));
      }
    }
  },
  postPicture: async (data: { rawFile: File; resizedPicture: unknown }) => {
    const url = `${ENTRYPOINT}/me`;
    try {
      const response = await fetchUtils.fetchJson(url, getRequestOptions('POST', data));
      return response.json;
    } catch (error) {
      handleHttpError(error as Error, t(tokens.dataProvider.picture.postError));
    }
  },
  deletePicture: async () => {
    const url = `${ENTRYPOINT}/me`;
    try {
      const response = await fetchUtils.fetchJson(url, getRequestOptions('DELETE'));
      return response.json;
    } catch (error) {
      handleHttpError(error as Error, t(tokens.dataProvider.picture.deleteError));
    }
  },
  postPersonalInfos: async (data: Partial<User>) => {
    const url = `${ENTRYPOINT}/me`;
    try {
      const response = await fetchUtils.fetchJson(url, getRequestOptions('POST', data));
      return response.json;
    } catch (error) {
      handleHttpError(error as Error, t(tokens.dataProvider.picture.postError));
    }
  },
  patchPassword: async (data: { currentPassword: string; newPassword: string }) => {
    const url = `${ENTRYPOINT}/my-profile/password`;
    try {
      const response = await fetchUtils.fetchJson(
        url,
        getRequestOptions('PATCH', data, 'application/merge-patch+json')
      );
      return response.json;
    } catch (error) {
      handleHttpError(error as Error, t(tokens.dataProvider.picture.postError));
    }
  },
  disableMember: async (memberId: string, data: DisableMemberModalPayload) => {
    const url = `${ENTRYPOINT}${memberId}/disable`;
    try {
      const response = await fetchUtils.fetchJson(
        url,
        getRequestOptions('PATCH', data, 'application/merge-patch+json')
      );
      return response.json;
    } catch (error: unknown) {
      console.error('Error details:', error);
      if (error instanceof Error) {
        handleHttpError(error, t(tokens.modale.disableMember.disableError));
      } else {
        handleHttpError(
          new Error('An error occurred'),
          t(tokens.modale.disableMember.disableError)
        );
      }
    }
  },
};

export default dataProvider;
