import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { warning, yellow, error, green, info, success } from 'src/theme/colors';
import { StatusOptionValuesEnum } from 'src/types/enums';

export type Option = {
  label: string;
  value: string;
};

const statusOptionsTrad = {
  pendingSign: t(tokens.filters.status.pendingSign),
  userAccepted: t(tokens.filters.status.userAccepted),
  userRefused: t(tokens.filters.status.userRefused),
  presidentAccepted: t(tokens.filters.status.presidentAccepted),
  presidentRefused: t(tokens.filters.status.presidentRefused),
  voided: t(tokens.filters.status.voided),
  expired: t(tokens.filters.status.expired),
  initialMembership: t(tokens.filters.status.initialMembership),
  declined: t(tokens.filters.status.declined),
  signed: t(tokens.filters.status.signed),
  initial: t(tokens.filters.status.initial),
} as const;

const statusOptionsValues = {
  initial: StatusOptionValuesEnum.INITIAL,
  initialMembership: StatusOptionValuesEnum.INITIAL_MEMBERSHIP,
  pendingSign: StatusOptionValuesEnum.PENDING_SIGN,
  userAccepted: StatusOptionValuesEnum.USER_ACCEPTED,
  userRefused: StatusOptionValuesEnum.USER_REFUSED,
  presidentAccepted: StatusOptionValuesEnum.PRESIDENT_ACCEPTED,
  presidentRefused: StatusOptionValuesEnum.PRESIDENT_REFUSED,
  voided: StatusOptionValuesEnum.VOIDED,
  expired: StatusOptionValuesEnum.EXPIRED,
  declined: StatusOptionValuesEnum.DECLINED,
  signed: StatusOptionValuesEnum.SIGNED,
} as const;

export const clubMembershipStatusOptions = [
  {
    label: statusOptionsTrad.initialMembership,
    value: statusOptionsValues.initial,
    tagColor: {
      background: info.alpha30,
      text: info.dark,
    },
  },
  {
    label: statusOptionsTrad.userAccepted,
    value: statusOptionsValues.userAccepted,
    tagColor: {
      background: yellow.alpha30,
      text: yellow.dark,
    },
  },
  {
    label: statusOptionsTrad.userRefused,
    value: statusOptionsValues.userRefused,
    tagColor: {
      background: error.alpha30,
      text: error.dark,
    },
  },
  {
    label: statusOptionsTrad.presidentAccepted,
    value: statusOptionsValues.presidentAccepted,
    tagColor: {
      background: info.alpha30,
      text: info.dark,
    },
  },
  {
    label: statusOptionsTrad.presidentRefused,
    value: statusOptionsValues.presidentRefused,
    tagColor: {
      background: error.alpha30,
      text: error.dark,
    },
  },
  {
    label: statusOptionsTrad.pendingSign,
    value: statusOptionsValues.pendingSign,
    tagColor: {
      background: green.alpha12,
      text: green.dark,
    },
  },
  {
    label: statusOptionsTrad.voided,
    value: statusOptionsValues.voided,
    tagColor: {
      background: warning.dark,
      text: warning.light,
    },
  },
  {
    label: statusOptionsTrad.declined,
    value: statusOptionsValues.declined,
    tagColor: {
      background: warning.dark,
      text: warning.light,
    },
  },
  {
    label: statusOptionsTrad.signed,
    value: statusOptionsValues.signed,
    tagColor: {
      background: success.alpha30,
      text: success.dark,
    },
  },
  {
    label: statusOptionsTrad.expired,
    value: statusOptionsValues.expired,
    tagColor: {
      background: error.dark,
      text: error.light,
    },
  },
];
