import { Typography } from '@mui/material';
import { Club } from 'src/admin-api-types';
import { formatContent } from 'src/utils/formatContent';
import { formatNbToDay } from 'src/utils/formatNbToDay';
import { hourToGridFormat } from 'src/utils/hourToGridFormat';

export const ClubSettingRenderer = ({ record }: { record: Club }) => {
  if (!record.clubSetting) return null;
  return (
    <Typography variant="body2">
      {`Les ${formatNbToDay(record.clubSetting.dayWeek)}s ${formatContent(
        record.clubSetting.format
      )} de ${hourToGridFormat(record.clubSetting.openingTime)} à ${hourToGridFormat(
        record.clubSetting.closingTime
      )}`}
    </Typography>
  );
};
