import { AccountCircle } from '@mui/icons-material';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';

export interface Item {
  title: string;
  path: string;
  icon: ReactElement;
  disabled?: boolean;
  label?: string;
  external?: boolean;
  items?: Item[];
  component?: React.ReactNode;
}

export interface Section {
  subheader?: string;
  items?: Item[];
}

const commonAdminSections = [
  {
    subheader: tokens.nav.others.settings.personal.subheader,
    items: [
      {
        title: tokens.nav.others.settings.personal.personalInfos.list,
        path: paths.others.settings.personal.personalInfos.list,
        icon: <AccountCircle fontSize="small" />,
      },
    ],
  },
];

const sectionsByRole = {
  ROLE_AGENCY: [...commonAdminSections],
  ROLE_SUPER_ADMIN: [...commonAdminSections],
  ROLE_ADMIN: [...commonAdminSections],
};

export const useMySettingsSections = (): Section[] => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return useMemo(() => {
    if (!user || user.roles.length === 0) return [];

    const baseSections: Section[] = sectionsByRole[user.roles[0]] || [];
    const sections: Section[] = [...baseSections];

    return sections.map((section) => ({
      ...section,
      subheader: section.subheader ? t(section.subheader) : undefined,
      items: section.items
        ? section.items.map((item) => ({
            ...item,
            title: t(item.title),
            icon: item.icon,
          }))
        : undefined,
    }));
  }, [t, user]);
};
