import { useReactAdminFilters } from './useReactAdminFilters';

// useInvitationsFilters.ts
export const useInvitationsFilters = () => {
  return useReactAdminFilters('invitations');
};

// useMembershipsFilters.ts
export const useMembershipsFilters = () => {
  return useReactAdminFilters('memberships');
};
