import React from 'react';
import { Pagination } from 'react-admin';
import { ListBaseLayout } from 'src/common/layouts/react-admin/ListBaseLayout';

import { ClubUserFilters } from '../filters/ClubUserFilters';
import { ClubUserGrid } from '../grid/ClubUserGrid';

export const ClubUserListTable: React.FC = () => {
  
  return (
    <ListBaseLayout>
      <ClubUserFilters />
      <ClubUserGrid />
      <Pagination />
    </ListBaseLayout>
  );
};
