/**
 * Convertit une date sous forme de chaîne en un format d'affichage d'heure UTC.
 *
 * @param {string | null | undefined} createdAt - La date de création sous forme de chaîne (au format ISO).
 * @returns {string} L'heure formatée en UTC (HH:mm), ou '-' si `createdAt` est nul ou indéfini.
 *
 * @example
 * // Pour une date reçue par le back : 2024-12-31T07:30:00+01:00
 * hourToGridFormat("2024-12-31T07:30:00+01:00");
 * // Retourne "07:30"
 *
 * @example
 * // Pour une date reçue par le back : 2024-12-31T07:30:00+05:00
 * hourToGridFormat("2024-12-31T07:30:00+05:00");
 * // Retourne également "07:30"
 *
 * @example
 * hourToGridFormat(null);
 * // Retourne "-"
 */
export const hourToGridFormat = (createdAt: string | null | undefined): string => {
  if (!createdAt) {
    return '-';
  }

  const [datePart, timePart] = createdAt.split('T');
  const [hour, minute] = timePart.split(':');

  return `${hour}:${minute}`;
};
