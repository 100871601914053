import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { warning, error, indigo, yellow, green } from 'src/theme/colors';
import { StatusOptionValuesEnum } from 'src/types/enums';

export type Option = {
  label: string;
  value: string;
};

const statusOptionsTrad = {
  guestAccepted: t(tokens.filters.status.guestAccepted),
  guestRefused: t(tokens.filters.status.guestRefused),
  presidentAccepted: t(tokens.filters.status.presidentAccepted),
  presidentRefused: t(tokens.filters.status.presidentRefused),
  aborted: t(tokens.filters.status.aborted),
  submitted: t(tokens.filters.status.submitted),
  expired: t(tokens.filters.status.expired),
} as const;

const statusOptionsValues = {
  guestAccepted: StatusOptionValuesEnum.GUEST_ACCEPTED,
  guestRefused: StatusOptionValuesEnum.GUEST_REFUSED,
  presidentAccepted: StatusOptionValuesEnum.PRESIDENT_ACCEPTED,
  presidentRefused: StatusOptionValuesEnum.PRESIDENT_REFUSED,
  aborted: StatusOptionValuesEnum.ABORTED,
  submitted: StatusOptionValuesEnum.SUBMITTED,
  expired: StatusOptionValuesEnum.EXPIRED,
} as const;

export const clubInvitationsStatusOptions = [
  {
    label: statusOptionsTrad.submitted,
    value: statusOptionsValues.submitted,
    tagColor: {
      background: yellow.alpha50,
      text: warning.dark,
    },
  },
  {
    label: statusOptionsTrad.presidentAccepted,
    value: statusOptionsValues.presidentAccepted,
    tagColor: {
      background: green.alpha12,
      text: green.dark,
    },
  },
  {
    label: statusOptionsTrad.presidentRefused,
    value: statusOptionsValues.presidentRefused,
    tagColor: {
      background: error.alpha30,
      text: error.dark,
    },
  },
  {
    label: statusOptionsTrad.guestAccepted,
    value: statusOptionsValues.guestAccepted,
    tagColor: {
      background: indigo.alpha12,
      text: indigo.dark,
    },
  },
  {
    label: statusOptionsTrad.guestRefused,
    value: statusOptionsValues.guestRefused,
    tagColor: {
      background: error.alpha30,
      text: error.dark,
    },
  },
  {
    label: statusOptionsTrad.expired,
    value: statusOptionsValues.expired,
    tagColor: {
      background: warning.dark,
      text: warning.light,
    },
  },
  {
    label: statusOptionsTrad.aborted,
    value: statusOptionsValues.aborted,
    tagColor: {
      background: warning.dark,
      text: warning.light,
    },
  },
];
