import { t } from 'i18next';
import { Show, TabbedShowLayout } from 'react-admin';
import { BreadCrumb } from 'src/common/components/breadcrumb';
import { DetailLayout } from 'src/common/layouts/react-admin/DetailLayout';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';

import { ClubShowGeneral } from './ClubShowGeneral';
import { ClubShowInvitations } from './ClubShowInvitations';
import { ClubShowMemberships } from './ClubShowMemberships';
import { ClubDetailHeader } from './components/ClubDetailHeader';

export const ClubShow = () => {
  const path = paths.club.clubs.list;

  return (
    <Show>
      <DetailLayout seoTitle={t(tokens.seo.club.title)}>
        <BreadCrumb
          linkText={t(tokens.breadcrumb.clubList)}
          to={path}
        />
        <ClubDetailHeader />
        <TabbedShowLayout>
          <TabbedShowLayout.Tab
            label="General"
            path=""
          >
            <ClubShowGeneral />
          </TabbedShowLayout.Tab>
          <TabbedShowLayout.Tab
            label="Adhésions"
            path="memberships"
          >
            <ClubShowMemberships />
          </TabbedShowLayout.Tab>
          {/* <TabbedShowLayout.Tab
            label="Invitations"
            path="invitations"
          >
            <ClubShowInvitations />
          </TabbedShowLayout.Tab> */}
        </TabbedShowLayout>
      </DetailLayout>
    </Show>
  );
};
