import { jwtDecode } from 'jwt-decode';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
} from 'src/contexts/auth/jwt/AuthProvider';
import { ENTRYPOINT } from 'src/data/provider/remote/dataProvider';
import { getAuthenticationHeader } from 'src/data/utils/getAuthenticationHeader';
import {
  LoginErrorResponse,
  LoginSuccessResponse,
  RequestForgotPasswordResponse,
  RequestForgotPasswordResponseFront,
  ResetForgotPasswordResponseFront,
  ResetForgotPasswordSuccessResponse,
  VerifyTwoFactorCodeResponse,
} from 'src/types/config';
import { User } from 'src/types/contexts/auth';

import { executeRefreshToken, handleCatchBlockError } from './utils';

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

/**
 * Notifies all subscribers with the new token and clears the subscriber list.
 *
 * @param {string} token - The new access token to pass to subscribers.
 */
const onRefreshed = (token: string) => {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
};

/**
 * Adds a callback function to the list of subscribers for the refresh event.
 *
 * @param {function} callback - The function to be called when the token is refreshed.
 */
const addRefreshSubscriber = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

const remoteAuthProvider = {
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loggedUser');
    return Promise.resolve();
  },
  verifyTwoFactorCode: async (code: string): Promise<VerifyTwoFactorCodeResponse> => {
    const request = new Request(`${ENTRYPOINT}/auth/2fa`, {
      method: 'POST',
      body: JSON.stringify({ authCode: code }),
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/ld+json',
        'Access-Control-Allow-Credentials': 'true',
      }),
    });
    try {
      const response = await fetch(request);
      const data = (await response.json()) as VerifyTwoFactorCodeResponse;
      if (!response.ok) {
        throw new Error(data.message);
      }
      return data;
    } catch (err: unknown) {
      throw err;
    }
  },

  login: async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<LoginSuccessResponse> => {
    const request = new Request(`${ENTRYPOINT}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ email: email, password }),
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/ld+json',
        'Access-Control-Allow-Credentials': 'true',
      }),
    });
    try {
      const response = await fetch(request);

      if (!response.ok) {
        const data = (await response.json()) as LoginErrorResponse;
        throw new Error(data.message);
      }
      const data = (await response.json()) as LoginSuccessResponse;
      return data;
    } catch (err) {
      throw new Error(err instanceof Error ? err.message : 'An error occurred');
    }
  },

  requestForgotPassword: async (email: string): Promise<RequestForgotPasswordResponseFront> => {
    const request = new Request(`${ENTRYPOINT}/auth/forgotten_password/request`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/ld+json',
        'Access-Control-Allow-Credentials': 'true',
      }),
    });
    try {
      const response = await fetch(request);
      const data = (await response.json()) as RequestForgotPasswordResponse;
      if (!response.ok) {
        throw new Error('An error occurred');
      }
      return { email: data.email, expiresAt: data.expiresAt } as RequestForgotPasswordResponseFront;
    } catch (err: unknown) {
      console.error(err);
      throw new Error('An error occurred');
    }
  },

  resetForgotPassword: async (
    password: string,
    token: string
  ): Promise<ResetForgotPasswordResponseFront> => {
    const request = new Request(`${ENTRYPOINT}/auth/forgotten_password/reset`, {
      method: 'POST',
      body: JSON.stringify({ password, token }),
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/ld+json',
        'Access-Control-Allow-Credentials': 'true',
      }),
    });
    try {
      const response = await fetch(request);
      const data = (await response.json()) as ResetForgotPasswordSuccessResponse;
      if (!response.ok) {
        throw new Error(data.detail);
      }
      return { passwordReset: data.passwordReset } as ResetForgotPasswordResponseFront;
    } catch (err: unknown) {
      handleCatchBlockError(err);
      throw err;
    }
  },
  checkAuth: () => {
    try {
      const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
      if (token === null) {
        return Promise.reject();
      }

      const decodedToken = jwtDecode(token);
      if (decodedToken.exp === undefined || new Date().getTime() / 1000 > decodedToken.exp) {
        return Promise.reject();
      }

      return Promise.resolve();
    } catch (e) {
      console.error('checkAuth error', e);
      return Promise.reject();
    }
  },
  checkError: (err: { status?: number; response?: { status?: number } }) => {
    const status = err.status ?? err.response?.status;
    if (status !== undefined && [401].includes(status)) {
      localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  /**
   * Retrieves the current authenticated user's information.
   * If the token is invalid, it attempts to refresh the token and retry.
   *
   * @returns {Promise<User>} A promise that resolves to the user object.
   * @throws Will throw an error if the user data retrieval fails or the session is expired.
   */
  me: async (): Promise<User> => {
    const fetchMe = async () => {
      const request = new Request(`${ENTRYPOINT}/me`, {
        method: 'GET',
        headers: getAuthenticationHeader(),
      });
      const response = await fetch(request);
      if (!response.ok) {
        const errorData = (await response.json()) as { message: string };
        if (response.status === 401 && errorData.message === 'Invalid JWT Token') {
          throw new Error('InvalidToken');
        }
        throw new Error(
          errorData.message || 'Erreur lors de la récupération des données utilisateur'
        );
      }
      return (await response.json()) as User;
    };

    try {
      return await fetchMe();
    } catch (error) {
      if (error instanceof Error && error.message === 'InvalidToken') {
        try {
          await remoteAuthProvider.refreshToken();
          return await fetchMe();
        } catch (refreshError) {
          console.error('Failed to refresh token:', refreshError);
          await remoteAuthProvider.logout();
          throw new Error('Session expired. Please log in again.');
        }
      }
      handleCatchBlockError(error);
      throw error;
    }
  },
  /**
   * Refreshes the authentication token.
   * If a refresh is already in progress, it subscribes to the refresh event.
   *
   * @returns {Promise<string>} A promise that resolves to the new access token.
   * @throws Will throw an error if the token refresh fails.
   */
  refreshToken: async (): Promise<string> => {
    if (!isRefreshing) {
      isRefreshing = true;
      try {
        const token = await executeRefreshToken();
        onRefreshed(token);
        isRefreshing = false;
        return token;
      } catch (error) {
        isRefreshing = false;
        console.error('Failed to refresh token:', error);
        localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
        localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
        throw error;
      }
    } else {
      return new Promise((resolve) => {
        addRefreshSubscriber(resolve);
      });
    }
  },
};

export default remoteAuthProvider;
