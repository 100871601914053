import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { DateField, FunctionField, TextField, useResourceContext } from 'react-admin';
import { ColumnHeaderLabel } from 'src/common/enums/react-admin';
import { DatagridLayout } from 'src/common/layouts/react-admin';
import { ClubSortByEnum, MemberSortByEnum } from 'src/data/enum/local/orderFilterEnum';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';
import { StatusField } from 'src/domains/club/clubs/components/react-admin/fields';
import { tokens } from 'src/locales/tokens';

import { ClubMembershipSourceEnum } from '../../enums/clubMembership-source-enum';

interface CenteredCellProps {
  children: React.ReactNode;
}

// TODO: Will be replaced by admin-api-types when it will be available
type Registration = {
  status?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  email?: string;
  phoneNumber?: string;
  organizationName?: string;
  clubName?: string;
  clubDepartment?: string;
  createdAt?: string;
  updatedAt?: string;
  id?: string;
};

const CenteredCell: React.FC<CenteredCellProps> = ({ children }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    {children}
  </div>
);

export const ClubMembershipGrid = () => {
  const resource = useResourceContext();

  return (
    <DatagridLayout content={t(tokens.domains.members.list.grid.noResult)}>
      <FunctionField
        source={ClubMembershipSourceEnum.FIRST_NAME}
        label={ColumnHeaderLabel.memberName}
        render={(record: Registration) => {
          return (
            <Stack>
              <Typography sx={{ fontSize: '0.875rem' }}>
                {record.firstName} {record.lastName?.toUpperCase()}
              </Typography>
              <TextField
                source={ClubMembershipSourceEnum.EMAIL}
                variant={'caption'}
              />
              <TextField
                source={ClubMembershipSourceEnum.PHONE}
                variant={'caption'}
              />
            </Stack>
          );
        }}
      />
      <TextField
        source={ClubMembershipSourceEnum.COMPANY}
        label={ColumnHeaderLabel.organization}
        reference={ClubMembershipSourceEnum.COMPANY}
      />
      {resource === ClubResourceEnum.REGISTRATIONS && (
        <TextField
          source={ClubMembershipSourceEnum.CLUB_NAME}
          label={ColumnHeaderLabel.clubName}
          reference={ClubMembershipSourceEnum.CLUB_NAME}
        />
      )}
      <FunctionField
        label={ColumnHeaderLabel.status}
        sortBy={ClubSortByEnum.ORDER_BY_STATUS}
        render={() => (
          <CenteredCell>
            <StatusField
              source={ClubMembershipSourceEnum.STATUS}
              list={'membership'}
            />
          </CenteredCell>
        )}
      />
      <TextField
        source={ClubMembershipSourceEnum.DEPARTMENT}
        label={ColumnHeaderLabel.department}
        sortBy={MemberSortByEnum.ORGANISATION_DEPARTMENT}
      />
      <DateField
        source={ClubMembershipSourceEnum.MODIFY_AT}
        label={ColumnHeaderLabel.modifyAt}
        textAlign="center"
      />
    </DatagridLayout>
  );
};
