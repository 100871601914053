import { Box, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { DetailLayout } from 'src/common/layouts/react-admin';
import { tokens } from 'src/locales/tokens';

import { MySettingsList } from '../MySettingsList';

interface MySettingsLayoutProps {
  children: React.ReactNode;
  readonly?: boolean;
}

export const MySettingsLayout = ({ children, readonly }: MySettingsLayoutProps) => {
  return (
    <DetailLayout seoTitle={t(tokens.seo.settings.title)}>
      <Typography variant="h4">{t(tokens.domains.settings.settingsHeaderTitle)}</Typography>
      <Stack
        direction="row"
        spacing={2}
      >
        <Box sx={{ px: 0, maxWidth: '20%', marginLeft: '-5px' }}>
          <MySettingsList />
        </Box>
        <Box sx={{ px: 1, width: '80%', height: '100%' }}>{children}</Box>
      </Stack>
    </DetailLayout>
  );
};
